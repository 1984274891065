import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject } from '@angular/core';

@Directive({
  selector: '[focus-select-all]',
  standalone: true,
})
export class FocusSelectAllDirective {
  @HostListener('focus')
  focus() {
    setTimeout(() => {
      // const i = `${this._elm.nativeElement.value || ''}`.length;
      // this._elm.nativeElement.setSelectionRange(0, i);
      this.document.execCommand('selectAll', false);
      // this._elm.nativeElement.select(); // it will f
    }, 0);
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}
}
