import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { storage } from '../decorators';
import { ICache } from '../decorators/cache.decorator';
import { useI18nRouter } from './i18n.service';

@Injectable({
  providedIn: 'root',
})
export class PathService implements ICache {
  readonly storageKey = 'PathService';

  get currentUrl() {
    return this._router.url;
  }

  @storage()
  returnUrl: string | null = null;

  private getReturnURL(defaultUrl = '/') {
    return (
      this._route.snapshot.queryParamMap.get('returnUrl') ||
      this.returnUrl ||
      defaultUrl
    );
  }
  private _router = useI18nRouter();

  constructor(private _route: ActivatedRoute) {}

  saveGo(toUrl: string[], backUrl = this.getReturnURL()) {
    const toTargetUrl = backUrl || this._router.url;
    this.returnUrl = toTargetUrl;

    this._router.navigate(toUrl, { queryParams: { returnUrl: toTargetUrl } });
  }

  goBack(defaultUrl = '/') {
    this.returnUrl = null;
    this._router.navigate([this.getReturnURL(defaultUrl)]);
  }

  goNotFound() {
    this._router.navigate(['/page/not-found']);
  }
}
