import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { BehaviorSubject, Observable, tap } from 'rxjs';

import { AutoDestroy } from '@nghedgehog/core';

import { LoadingMaskComponent } from './loading-mask.component';
import { input } from '@angular/core';

@Directive({
  selector: '[loading]',
  standalone: true,
})
export class LoadingBlockDirective
  extends AutoDestroy
  implements AfterViewInit, OnDestroy, OnInit
{
  currentView = new BehaviorSubject<any>(() => {
    //
  });

  checkRender$ = new BehaviorSubject<any>(undefined);

  /**
   * when that obs next will trigger show loading again
   */
  loadingReload$ = input<Observable<any> | undefined>();

  @Input()
  set loading(value: any) {
    this._context.$implicit = this._context.loading = value;

    this.checkRender$.next(value);
  }

  loadingElse = input<TemplateRef<any> | null>(null);
  loadingMask = input<TemplateRef<any> | null>(null);
  loadingInitShow = input<'else' | 'loading'>('loading');
  loadingShowZero = input(false);

  private _context: LoadingContext = new LoadingContext();
  private _thenTemplateRef: TemplateRef<LoadingContext> | null = null;
  private _thenViewRef: EmbeddedViewRef<LoadingContext> | null = null;
  private checkOnce = false;
  private renderFirstTime = true;
  private ref!: ChangeDetectorRef;
  private _cdRef = inject(ChangeDetectorRef);

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<LoadingContext>,
  ) {
    super();
    this._thenTemplateRef = templateRef;
  }

  ngOnInit() {
    this.checkRender$
      .pipe(
        tap((value) => this.checkRender(value)),
        this.takeUntilAppDestroy,
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.currentView
      .pipe(
        tap((fn) => {
          fn();
        }),
        this.takeUntilAppDestroy,
      )
      .subscribe();

    if (this.loadingReload$()) {
      this.loadingReload$()!
        .pipe(
          tap(() => {
            this.checkOnce = false;
            this._thenViewRef = null;
            this.renderLoadingOrElse();
          }),
          this.takeUntilAppDestroy,
        )
        .subscribe();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.ref) {
      this.ref.detach();
    }
  }

  private checkRender(value: any) {
    const isArray = Array.isArray(value);

    if (
      (!isArray && value) || // if is not array and has value
      // if is array and length more than 0
      (isArray && (value.length > 0 || this.loadingShowZero()))
    ) {
      if (!this._thenViewRef && this._thenTemplateRef) {
        this._viewContainer.clear();
        this._thenViewRef = this._viewContainer.createEmbeddedView(
          this._thenTemplateRef,
          this._context,
        );
      }
    } else {
      // use BehaviorSubject to do thing when ngAfterViewInit
      this.renderLoadingOrElse();
    }

    this._cdRef.detectChanges();
  }

  private renderLoadingOrElse() {
    this.currentView.next(() => {
      this._viewContainer.clear();

      if (
        (this.renderFirstTime && this.loadingInitShow() === 'else') ||
        this.checkOnce
      ) {
        if (this.loadingElse()) {
          this._viewContainer.createEmbeddedView(this.loadingElse()!);
        }
      } else {
        this.renderLoading();
      }

      this.renderFirstTime = false;
      this.checkOnce = true;
    });
  }

  private renderLoading() {
    if (this.loadingMask()) {
      this._viewContainer.createEmbeddedView(this.loadingMask()!);
    } else {
      const componentRef =
        this._viewContainer.createComponent(LoadingMaskComponent);

      this.ref = componentRef.changeDetectorRef;

      this.ref.detectChanges();
    }
  }
}

class LoadingContext {
  public $implicit: any = null;
  public loading: any = null;
}
