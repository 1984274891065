import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import localforage from 'localforage';
import { NgxRxAlertModel, NgxRxAlertService, ok } from 'ngx-rx-alert';
import {
  catchError,
  combineLatest,
  defer,
  EMPTY,
  fromEvent,
  merge,
  Observable,
  of,
  switchMap,
  tap,
  throttleTime,
  throwError,
} from 'rxjs';

import { defaultCacheKey, defaultI18nLang } from '@alan-apps/api-interfaces';
import { detectBot } from '@alan-apps/utils';
import { TranslateService } from '@ngx-translate/core';

import { ICache } from '../decorators/cache.decorator';
import { storage } from '../decorators/storage.decorator';

declare const ngDevMode: boolean | undefined;

// * that get from index.html and set from server
const cacheObject = (window as any)['__EXPIRE_KEY__'] || {};

export const ONE_MIN = 60 * 1000;
export const ONE_HOUR = ONE_MIN * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_YEAR = ONE_DAY * 365;

export type CacheOptions = {
  /**
   * cache time, default is 1 hour, unit is `ms`
   */
  timeMs?: number;
  /**
   * without locale suffix
   */
  withoutLocale?: boolean;
};

const EXPIRE_TIME = ONE_MIN * 5;

@Injectable({
  providedIn: 'root',
})
export class CacheService implements ICache {
  readonly storageKey = 'CacheService';

  globalStorage = localforage.createInstance({
    name: 'orange-house',
  });

  get defaultKey() {
    return cacheObject[defaultCacheKey];
  }

  @storage()
  cacheDefaultKey: string | null = null;

  @storage()
  ignoreCache = false;

  getVersionAndReload$ = this._http
    .get<{ version: string }>('/api/version')
    .pipe(
      switchMap((x) => {
        if (this.defaultKey !== x.version) {
          return combineLatest([
            this._translate.get('系統新版本'),
            this._translate.get('發現新版本，是否立即更新？'),
          ]).pipe(
            switchMap(([title, content]) => {
              console.log(title, content);
              return this._alc.confirm(
                new NgxRxAlertModel(title, content, 'info', true),
                {
                  disabledBackdropClick: true,
                },
              );
            }),
          );
        }
        return of(null);
      }),
      ok(() => window.location.reload()),
      catchError(() => EMPTY),
    );

  checkAppVersion$ = merge(
    fromEvent(window, 'focus'),
    fromEvent(this.document.body, 'click'),
  ).pipe(
    throttleTime(EXPIRE_TIME),
    switchMap(() => this.getVersionAndReload$),
  );

  private getLocal: () => string = () => defaultI18nLang;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _http: HttpClient,
    public _translate: TranslateService,
    private _alc: NgxRxAlertService,
  ) {
    // * at production, check app version
    if (!(typeof ngDevMode === 'undefined' || ngDevMode)) {
      this.checkAppVersion$.subscribe();
    }

    (window as any)['setIgnoreCache'] = (value: boolean) =>
      this.setIgnoreCache(value);

    if (this.ignoreCache) {
      console.log('%cIgnore cache mode', 'color:red;font-size:2em');
    }
  }

  setIgnoreCache(value: boolean) {
    this.ignoreCache = value;
  }

  setLocalFn(fn: () => string) {
    this.getLocal = fn;
  }

  checkAppVersion() {
    const defaultCacheKey = this.defaultKey;

    if (defaultCacheKey && defaultCacheKey !== this.cacheDefaultKey) {
      this.clear();
      this.cacheDefaultKey = defaultCacheKey;
    }
  }

  clear() {
    this.globalStorage.clear();
  }

  /**
   * cache obs result, default time is 60s
   */
  cache<T extends Observable<any>>(
    obs: T,
    sourceKey: string,
    { timeMs = ONE_YEAR, withoutLocale = false }: CacheOptions = {},
  ): T {
    // if that is bot, return origin obs directly
    if (detectBot(navigator?.userAgent)) {
      return obs;
    }

    const key = withoutLocale ? sourceKey : `${sourceKey}_${this.getLocal()}`;
    const serverCacheKey = sourceKey.split('.').slice(0, 2).join('.');

    const version =
      cacheObject[serverCacheKey] ||
      // default version from server
      cacheObject[defaultCacheKey] ||
      '1';

    const expireKey = `${key}_$$_${version}`;

    return combineLatest([
      defer(() => this.globalStorage.getItem<number | null>(expireKey)),
      defer(() => this.globalStorage.getItem<any>(key)),
    ]).pipe(
      switchMap(([expireDateTime, cacheValue]) => {
        if (
          this.ignoreCache ||
          !cacheValue ||
          !expireDateTime ||
          (expireDateTime && Date.now() > +expireDateTime)
        ) {
          return obs.pipe(
            tap((value) => {
              this.globalStorage.setItem(key, value);

              const cacheExpireDateTime = Date.now() + timeMs;

              this.globalStorage.setItem(expireKey, cacheExpireDateTime);
            }),
            // when external obs emit error, return cache value
            catchError((err) => {
              if (cacheValue) {
                return of(cacheValue);
              }

              return throwError(() => err);
            }),
          );
        }

        return of(cacheValue);
      }),
    ) as any;
  }
}
