import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, catchError, pipe, tap } from 'rxjs';

export const nonDataToNotFound = <T>(toPath = '/page/not-found') => {
  const router = inject(Router);

  function toNotFound() {
    router.navigateByUrl(toPath);
  }

  return pipe(
    tap<T>((value: unknown) => {
      if (value) return;

      toNotFound();
    }),
    catchError(() => {
      toNotFound();

      return EMPTY;
    }),
  );
};
