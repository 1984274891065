import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';

import { NgxRxAlertModel, NgxRxAlertService } from 'ngx-rx-alert';
import {
  catchError,
  combineLatest,
  finalize,
  identity,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';

import {
  FORBIDDEN_RESOURCE,
  HAS_RELATED_DATA,
  ID_EXIST_MESSAGE,
} from '@alan-apps/api-interfaces';
import { CdkService } from '@nghedgehog/core';
import { LoadingBarService } from '@ngx-loading-bar/core';

import { PathService } from '../../services/path.service';
import { useOnBrowser } from '../../utils';
import { BLOCK_VIEW_TOKEN, BlockViewComponent } from './block-view.component';
import { APP_URL } from '../../constants';

export type BlockViewOptions = {
  mask?: boolean;
  title?: string;
  /**
   * @default true
   */
  catchError?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BlockViewService {
  private blockCount = 0;

  private _cdk = inject(CdkService);
  private _alc = inject(NgxRxAlertService);
  private _path = inject(PathService);
  private loadingBar = inject(LoadingBarService);
  private url = inject(APP_URL);

  private portalHost = this._cdk.createBodyPortalHost();
  private loadingBarRef = this.loadingBar.useRef();

  block = useOnBrowser(({ mask, title }: BlockViewOptions = {}) => {
    return timer(0).pipe(
      tap(() => this.loadingBarRef.start()),
      tap(() => {
        this.blockCount++;

        if (mask) {
          this.showBLock(title);
        }
      }),
    );
  }, of(null));

  unblock = useOnBrowser((cb?: () => void) => {
    return timer(0).pipe(
      tap(() => this.loadingBarRef.stop()),
      tap(() => {
        if (this.blockCount > 0) {
          this.blockCount--;
        }

        cb?.();
      }),
      finalize(() => {
        if (this.blockCount <= 0) {
          this.blockCount = 0;
          this.removeBLock();
        }
      }),
    );
  }, of(null));

  // http handler block
  next<T>(
    obs: Observable<T>,
    options?: BlockViewOptions,
    noErrorHandler = false,
  ): Observable<T> {
    const isCatchError = options?.catchError ?? true;

    return this.block(options).pipe(
      switchMap(() => obs),
      isCatchError
        ? catchError((error) => this.handleError(error, noErrorHandler))
        : identity,
      finalize(() => this.unblock().subscribe()),
    );
  }

  noBlockNext<T>(
    method: Observable<any>,
    noErrorHandler = false,
  ): Observable<T> {
    return method.pipe(
      catchError((error: Response) => this.handleError(error, noErrorHandler)),
    );
  }

  private handleError(error: Response | Error, noErrorHandler: boolean) {
    const showMessage$ = noErrorHandler
      ? of(null)
      : this._alc.alert(
          new NgxRxAlertModel('錯誤訊息', this.getMessage(error), 'error'),
        );

    combineLatest([this.unblock(), showMessage$]).subscribe();

    return throwError(() => error);
  }

  private getMessage(error: Response | Error) {
    if (error instanceof Error) {
      if ((error as any)['networkError']) {
        return '網路錯誤，無法連線伺服器，請確認網路狀況後重試';
      }

      switch (error.message) {
        case ID_EXIST_MESSAGE:
          return '編號已存在，請更換編號';
        case HAS_RELATED_DATA:
          return '存在關聯資料，無法刪除';
        case FORBIDDEN_RESOURCE:
          return '權限不存，執行失敗';
        default:
          break;
      }
    }

    switch ((error as Response).status) {
      // case 400:
      case 401:
        this._path.saveGo(this.url.nonAuthentication, this._path.currentUrl);
        // reqObj = new BadError(error.json());
        break;
      case 409:
        return '編號已存在，請更換編號';
    }

    return '伺服器發生錯誤，請聯絡管理者';
  }

  private showBLock(title?: string) {
    if (!this.portalHost.hasAttached()) {
      const component = new ComponentPortal(
        BlockViewComponent,
        undefined,
        this._cdk.createInjector(BLOCK_VIEW_TOKEN, title),
      );
      this.portalHost.attach(component);
    }
  }

  private removeBLock() {
    if (this.portalHost.hasAttached()) this.portalHost.detach();
  }
}
