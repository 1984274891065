export enum PropertyContractTypes {
  new,
  renew,
  breakRecord,
  sellNew,
}

export enum PropertyContractInvoiceTypes {
  二聯式,
  三聯式,
  收據,
  預開發票,
}

export const propertyContractInvoiceTypeList = [
  PropertyContractInvoiceTypes.二聯式,
  PropertyContractInvoiceTypes.三聯式,
  PropertyContractInvoiceTypes.收據,
  PropertyContractInvoiceTypes.預開發票,
];

export const propertyContractTypeList = [
  PropertyContractTypes.new,
  PropertyContractTypes.renew,
  PropertyContractTypes.breakRecord,
  PropertyContractTypes.sellNew,
];

export enum PropertyContractCustomerTypes {
  personal,
  group,
  company,
  other,
}

export const propertyContractCustomerTypeList = [
  PropertyContractCustomerTypes.personal,
  PropertyContractCustomerTypes.group,
  PropertyContractCustomerTypes.company,
  PropertyContractCustomerTypes.other,
];
