import { DateFnsAdapter } from '@angular/material-date-fns-adapter';

import { format, getDate, getMonth, getYear } from 'date-fns';
import { zhTW } from 'date-fns/locale';

const START_YEAR = 1911;
export class TWDateFnsAdapter extends DateFnsAdapter {
  override getYearName(date: Date, ...args: any[]): string {
    return `民國${this.getTaiwanYear(date)}`;
  }
  private getTaiwanYear(date: Date) {
    return getYear(date) - START_YEAR;
  }

  override parse(value: string, parseFormat: string) {
    if (!value) return null;

    let v = value
      .replace('民國', '')
      .replace('年', '/')
      .replace('月', '/')
      .replace('日', '');

    const [year, month, day] = v.trim().split('/');

    if (year && month && day) {
      v = `${+year + START_YEAR}/${month}/${day}`;
    }

    const toDate = super.parse(
      v,
      parseFormat === 'taiwan-date' ? 'yyyy/MM/dd' : parseFormat,
    );

    if (toDate && this.isValid(toDate)) {
      return toDate;
    }
    return null;
  }
  override format(date: Date, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error('MomentDateAdapter: Cannot format invalid date.');
    }

    if (displayFormat === 'taiwan-date') {
      const year = this.getTaiwanYear(date);
      const month = getMonth(date) + 1;

      return `民國${year}年${month}月${getDate(date)}日`;
    }

    if (displayFormat === 'taiwan-date MMM') {
      const year = this.getTaiwanYear(date);
      const month = getMonth(date) + 1;
      return `民國${year}年${month}月`;
    }

    return format(date, displayFormat, { locale: zhTW });
  }
}
