<div cdkTrapFocus cdkTrapFocusAutoCapture>
  <div class="content" role="dialog">
    @if (classList[data.type!]; as typeData) {
      <h5 class="mb-2 flex gap-2">
        <mat-icon [ngClass]="typeData.class" class="flex-none">{{
          typeData.icon
        }}</mat-icon>
        <!-- <i class="mat-icon material-icons" [ngClass]="typeData.class">{{typeData.icon}}</i> -->
        @if (data.title) {
          <span [ngClass]="{ 'font-normal': data.message === '' }">{{
            data.title
          }}</span>
        }
        <!-- use for center the title  -->
        <i></i>
      </h5>
    }
    @if (data.message; as message) {
      <article [innerHTML]="message"></article>
    }

    @if (templateRef) {
      <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
    }

    @if (data.hasInput) {
      <form [formGroup]="formGroup" (ngSubmit)="ok()">
        @switch (data.inputType) {
          @case ('textarea') {
            @if (data.inputType === 'textarea') {
              <mat-form-field color="accent" class="w-full">
                <textarea
                  matInput
                  formControlName="message"
                  cdkFocusInitial
                  [required]="isRequired"
                  [type]="type"
                  cdkTextareaAutosize
                  matAutosizeMaxRows="3"
                  cdkAutosizeMinRows="3"
                  autocomplete="off"
                ></textarea>
                @if (message!.invalid && message!.errors?.['required']) {
                  <mat-error> 欄位必須填寫 </mat-error>
                }
              </mat-form-field>
            }
          }
          @case ('input') {
            <mat-form-field color="accent" class="w-full">
              <input
                matInput
                cdkFocusInitial
                [type]="type"
                [required]="isRequired"
                autocomplete="off"
                formControlName="message"
              />
              @if (message!.invalid && message!.errors?.['required']) {
                <mat-error> 欄位必須填寫 </mat-error>
              }
            </mat-form-field>
          }
        }
      </form>
    }
  </div>

  <div class="button-area">
    <button
      mat-raised-button
      color="primary"
      (click)="ok()"
      #okElm
      cdkFocusInitial
    >
      {{ data.confirmText }}
    </button>
    @if (isConfirm) {
      <button mat-raised-button color="warn" (click)="cancel()">
        {{ data.cancelText }}
      </button>
    }
  </div>
</div>
