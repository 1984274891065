import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename',
  standalone: true,
})
export class FilenamePipe implements PipeTransform {
  transform(value: string | undefined | Blob): any {
    try {
      if (typeof value === 'string') {
        return value.split('_').pop();
      }
    } catch (error) {
      return '';
    }
  }
}
