import { BehaviorSubject, skip, tap } from 'rxjs';

// TODO: find way to get storage service
const storageService = {
  setItem: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  getItem: (key: string) => {
    return localStorage.getItem(key);
  },
};

export const storage = () => (target: any, key: string) => {
  let value = target[key];
  let ready = false;
  let cacheKey: string;

  function cacheValue(newVal: any) {
    storageService.setItem(cacheKey, JSON.stringify(newVal));
  }

  function initCacheKey(this: any) {
    const storageKey = this.storageKey ?? target.constructor.name;
    cacheKey = `${storageKey}.${key}`;

    const source = storageService.getItem(cacheKey);
    const tmpValue = source ? JSON.parse(source) : null;
    return tmpValue;
  }

  function init(this: any) {
    const tmpValue = initCacheKey.call(this);

    if (tmpValue !== null && value !== tmpValue) {
      value = tmpValue;
    }

    ready = true;
  }

  function obsInit(this: any, obs$: BehaviorSubject<unknown>) {
    initCacheKey.call(this);

    const tmpValue = initCacheKey.call(this);
    if (tmpValue !== null && obs$.value !== tmpValue) {
      obs$.next(tmpValue);
    }

    obs$
      .pipe(
        // first emit be ignore
        skip(1),
        tap((val) => {
          cacheValue(val);
        }),
      )
      .subscribe();

    value = obs$;
    ready = true;
  }

  const getter = function (this: {
    get: () => any;
    set: (
      this: {
        get: () => any;
        set: (newVal: any) => void;
        enumerable: true;
        configurable: true;
      },
      newVal: any,
    ) => void;
    enumerable: true;
    configurable: true;
  }) {
    if (!ready) init.bind(this)();

    return value;
  };

  const setter = function (
    this: {
      get: () => any;
      set: (newVal: any) => void;
      enumerable: true;
      configurable: true;
    },
    newVal: any,
  ) {
    const isObs = newVal instanceof BehaviorSubject;

    if (!ready) {
      if (isObs) {
        obsInit.bind(this)(newVal);
      } else {
        init.bind(this)();
      }
    } else if (!isObs) {
      cacheValue(newVal);
      value = newVal;
    }
  };

  if (delete target[key]) {
    Object.defineProperty(target, key, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true,
    });
  }
};
