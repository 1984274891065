import { AbstractControl } from '@angular/forms';

import { defer, distinctUntilChanged, map, startWith } from 'rxjs';

const defaultGetter = <T extends AbstractControl<any, any>>(formControl: T) =>
  formControl;

export const fromFormValue = <
  R,
  T extends AbstractControl<any, any> = AbstractControl<any, any>,
>(
  formControl: T,
  getter: (formControl: T) => AbstractControl<any, any> | R = defaultGetter,
) => {
  const getValue = () => {
    const result = getter(formControl);

    if (result instanceof AbstractControl) {
      return result.value;
    }
    return result;
  };

  return defer(() => formControl.valueChanges.pipe(startWith(getValue()))).pipe(
    map(() => getValue()),
    distinctUntilChanged(),
  );
};
