import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { defaultI18nLang } from '@alan-apps/api-interfaces';

import { I18nService } from '../services/i18n.service';

export const i18nGuard: CanActivateFn = (route, state) => {
  const i18n = inject(I18nService);

  const lang = route.paramMap.get('lang') || defaultI18nLang;
  console.log('user language', lang);

  if (i18n.language === lang) return true;

  i18n.setLang(lang, { reload: false });

  return true;
};

export const firstI18nGuard = () => {
  const i18n = inject(I18nService);
  if (i18n.detected) return true;

  const lang = i18n.detectLanguage();

  i18n.setLang(lang, { reload: true });
  i18n.detected = true;

  return true;
};
