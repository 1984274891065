import { map, Observable, pairwise, startWith } from 'rxjs';

export const fromPrevious = <T extends Observable<any>>(obs: T) => {
  return obs.pipe(
    startWith(undefined),
    pairwise(),
    map(([oldValue]) => {
      return oldValue;
    }),
  );
};
