import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

// https://forum.ionicframework.com/t/inserting-html-via-angular-2-use-of-domsanitizationservice-bypasssecuritytrusthtml/62562/5
@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  public transform(
    path: string | null = '',
    type: string = 'html',
    defaultValue?: string,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (!path) path = '';

    if (type !== 'html') {
      path = this.pathHandler(path, defaultValue);
    }

    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(path);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(path);
      case 'background-image':
        return this._sanitizer.bypassSecurityTrustStyle(`url('${path}')`);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(path);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(path);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(path);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  pathHandler(path: string, defaultValue?: string) {
    if (!path) {
      path = defaultValue || 'assets/img/avatar.jpg';
    }

    if (path.search(/^(https?:\/\/)|^(\/?assets)|^data:image/gim) !== -1) {
      return path;
    }

    return path;
  }
}
