@if (encodeURI$ | async; as encodeURI) {
  <a
    mat-ripple
    class="text-[#3b5998] icon-button"
    (click)="
      share(
        'https://' +
          urlHeader +
          '.facebook.com/sharer/sharer.php?u=' +
          encodeURI
      )
    "
  >
    <i class="icon-facebook text-lg"></i>
  </a>
  <a
    mat-ripple
    (click)="
      share('https://social-plugins.line.me/lineit/share?url=' + encodeURI)
    "
    class="text-[#00c300] icon-button"
  >
    <i class="icon-line text-lg"></i>
  </a>
  <button mat-ripple (click)="shareURL()" type="button" class="icon-button">
    <mat-icon>share</mat-icon>
  </button>
}
