import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { NgxRxModalOption, NgxRxModalService } from 'ngx-rx-modal';
import { Observable, Subscriber } from 'rxjs';

import {
  NgxRxAlertComponent,
  NgxRxAlertInputData,
} from './ngx-rx-alert.component';
import {
  DIALOG_TYPE,
  NgxRxAlertModel,
  NgxRxAlertModel2,
  NgxRxAlertOption,
} from './ngx-rx-alert.model';

type NgxRxAlertItem = NgxRxAlertModel | NgxRxAlertModel2 | string;

@Injectable({
  providedIn: 'root',
})
export class NgxRxAlertService {
  constructor(
    private _modal: NgxRxModalService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  confirm(obj: NgxRxAlertItem, option: NgxRxAlertOption = {}) {
    return this.openDialog(obj, DIALOG_TYPE.CONFIRM, option);
  }

  alert(
    obj: NgxRxAlertModel | NgxRxAlertModel2 | string,
    option: NgxRxAlertOption = {},
  ) {
    return this.openDialog(obj, DIALOG_TYPE.ALERT, option);
  }

  private openDialog(
    obj: NgxRxAlertModel | NgxRxAlertModel2 | string,
    type: DIALOG_TYPE,
    option: NgxRxAlertOption,
  ) {
    const _obj = (() => {
      if (typeof obj === 'string') {
        return new NgxRxAlertModel2({ title: obj, message: '' });
      }

      if (obj instanceof NgxRxAlertModel) {
        // TODO: migrate code, wait all be to alert2
        return new NgxRxAlertModel2({
          message: obj.message,
          title: obj.title,
          type: obj.type,
          disableClose: obj.disableClose,
          hasInput: obj.hasInput,
          inputType: obj.inputType,
          inputValidator: obj.inputValidator,
        });
      }

      return obj;
    })();

    // give default value
    _obj.options.type ??= 'info';
    _obj.options.inputType ??= 'textarea';
    _obj.options.inputValidator ??= null;
    _obj.options.cancelText ??= '取消';
    _obj.options.confirmText ??= '確定';

    const animate = this.getAnimate(_obj.options);

    return new Observable((observer: Subscriber<any>) => {
      this._modal
        .open(NgxRxAlertComponent, {
          data: <NgxRxAlertInputData>{
            data: _obj,
            type,
          },
          disableClose: _obj.options.disableClose,
          disableCloseButton: true,
          backdropClass: 'backdrop center',
          panelClass: 'bg-white',
          panelStyle: {
            // height: '50%',
            width: '50%',
          },
          windowAnimate: animate,
          disableBackdropClick: option.disabledBackdropClick,
          backdropStyle: option.backdropStyle,
          noRedirect: option.noRedirect,
        })
        .subscribe((result) => {
          if (type === DIALOG_TYPE.ALERT) {
            result = true;
          }
          observer.next(result);
          observer.complete();
        });
    });
  }

  /**
   * that method will change all window alert to be our alert
   */
  changeNormalAlert() {
    if (isPlatformBrowser(this.platformId)) {
      window.alert = (msg) => {
        this.alert(msg).subscribe();
      };
    }
  }

  private getAnimate(
    _obj: NgxRxAlertModel2['options'],
  ): NgxRxModalOption['windowAnimate'] {
    switch (_obj.type) {
      case 'success':
      case 'info':
        return 'zoomIn';
      case 'warning':
      case 'error':
        return 'bounceInDown';
    }

    return undefined;
  }
}
