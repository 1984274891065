import {
  animateChild,
  AnimationReferenceMetadata,
  AnimationTransitionMetadata,
  group,
  query,
  transition,
  trigger,
} from '@angular/animations';

import {
  bounceInDown,
  bounceOutDown,
  fadeIn,
  fadeOut,
  slideInLtoR,
  slideInRtoL,
  zoomIn,
  zoomOut,
} from './animations';

// query('@*', stagger(200, animateChild()), { optional: true })
export function animateChildTransition(
  state: string,
  ani: AnimationReferenceMetadata,
): AnimationTransitionMetadata {
  return transition(state, [
    group([ani, query('@*', animateChild(), { optional: true })]),
  ]);
}

export const allAnimation = trigger('animate', [
  animateChildTransition('* => fadeIn', fadeIn),
  animateChildTransition('fadeIn => void, * => fadeOut', fadeOut),
  animateChildTransition('* => bounceInDown', bounceInDown),
  animateChildTransition(
    'bounceInDown => void, * => bounceOutDown',
    bounceOutDown,
  ),
  animateChildTransition('* => zoomIn', zoomIn),
  animateChildTransition('zoomIn => void, * => zoomOut', zoomOut),
  transition('* => slideInRtoL', slideInRtoL),
  transition('slideInRtoL => void', slideInLtoR),
]);
