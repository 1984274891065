import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * This pipe is used to hide random characters in a string.
 */
@Pipe({
  name: 'sensitive',
  standalone: true,
})
export class SensitivePipe implements PipeTransform {
  transform(val: string, fixed: number[] = []) {
    return hideRandomCharacters(val, fixed);
  }
}

function hideRandomCharacters(str?: string, fixed: number[] = []) {
  if (!str) return '';

  // Convert the string to an array of characters
  const chars = str.split('');

  // Calculate the number of characters to hide
  const numCharsToHide = fixed.length || Math.floor(chars.length / 4);

  // Loop through and hide random characters
  for (let i = 0; i < numCharsToHide; i++) {
    // Generate a random index between the second and second-to-last character
    // so we don't hide the first or last character
    const indexToHide = fixed.length
      ? fixed[i]
      : Math.floor(Math.random() * (chars.length - 2)) + 1;

    // Replace the character at the random index with an asterisk
    chars[indexToHide] = '*';
  }

  // Convert the array back to a string and return it
  return chars.join('');
}
