// import { StringHandler } from '@alan-apps/utils';
// import {
//   Attribute,
//   Directive,
//   ElementRef,
//   forwardRef,
//   HostListener,
//   Inject,
//   InjectionToken,
//   Input,
//   model,
// } from '@angular/core';
// import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
// import { input } from '@angular/core';

// export const NUMBER_ONLY = new InjectionToken<{ min: number; max?: number }>(
//   'NUMBER_ONLY',
// );

// const EXE_COUNTER_VALUE_ACCESSOR_2: any = {
//   provide: NG_VALUE_ACCESSOR,
//   useExisting: forwardRef(() => NumberOnlyDirective),
//   multi: true,
// };

// @Directive({
//   // tslint:disable-next-line:directive-selector
//   selector: '[number-only]',
//   providers: [EXE_COUNTER_VALUE_ACCESSOR_2],
//   standalone: true,
// })
// export class NumberOnlyDirective implements ControlValueAccessor {
//   @Input()
//   get option() {
//     return this._option;
//   }
//   set option(value) {
//     this._option = value;
//     if (this._elm) {
//       this.checkValue(this._elm.nativeElement.value);
//     }
//   }
//   private _option!: { min: number; max?: number };

//   // eslint-disable-next-line @angular-eslint/no-input-rename
//   count = input(null, {
//     alias: 'value',
//     transform: (value: string | number | null) => {
//       const defaultValue = this.allowZero() ? 0 : null;
//       let toValue =
//         (this.float() ? +value! : Math.floor(+value!)) || defaultValue;
//       if (this.type === 'number') {
//         if (this.float()! && toValue) {
//           toValue = +toValue.toFixed(this.floatFixed());
//         }

//         this.onChange(toValue);
//       } else {
//         this.onChange(toValue ? toValue!.toString() : defaultValue);
//       }
//       this._elm.nativeElement.value =
//         toValue! === 0 ? defaultValue : +this.count!;

//       return toValue;
//     },
//   });
//   disabled = model<boolean | undefined>();

//   whenCheck = input<'input' | 'blur'>('blur');
//   enterCheck = input(true);

//   float = input(false);
//   floatFixed = input(2);
//   allowZero = input(false);

//   @HostListener('keydown', ['$event', '$event.target.value'])
//   keydown(e: KeyboardEvent, value: any) {
//     if (this.enterCheck() && e.code === 'Enter') {
//       this.checkValue(value);
//     }
//   }

//   @HostListener('blur', ['$event.target.value'])
//   blur(value: unknown) {
//     this.onTouched();
//     if (this.whenCheck() === 'blur') {
//       this.checkValue(value);
//     }
//   }

//   @HostListener('input', ['$event.target.value'])
//   change(value: unknown) {
//     this.onTouched();
//     if (this.whenCheck() === 'input') {
//       this.checkValue(value);
//     }
//   }

//   private onChange = (_: any) => {
//     //
//   };
//   private onTouched = () => {
//     //
//   };

//   constructor(
//     private _elm: ElementRef,
//     @Attribute('type') private type: string,
//     @Inject(NUMBER_ONLY) public data: { min: number; max?: number },
//   ) {
//     // console.log(type);
//     if (data && !this._option) {
//       this._option = data;
//     }

//     // if (this.isNoPass(this.count)) {
//     //   this.count = this.option.min || 0;
//     // }
//   }

//   private checkValue(e: any) {
//     if (e === null || e === '') {
//       this.count = this._option.min || null;
//     } else if (new StringHandler(e).isNumeric()) {
//       switch (this.isNoPass(+e)) {
//         case 1:
//           this.count = this._option.min || null;
//           break;
//         case 2:
//           this.count = this._option.max || null;
//           break;
//         case 0:
//           this.count = e;
//           break;
//       }
//     }
//     if (this.count === 0) {
//       this._elm.nativeElement.value = null;
//     } else {
//       this._elm.nativeElement.value = +this.count! || null;
//     }
//   }

//   writeValue(value: any) {
//     this.count = value;
//   }
//   registerOnChange(fn: (value: any) => any) {
//     this.onChange = fn;
//   }
//   registerOnTouched(fn: () => any) {
//     this.onTouched = fn;
//   }
//   setDisabledState(isDisabled: boolean) {
//     this.disabled.set(isDisabled);
//   }

//   /**
//    * check number is in range
//    * @param num number
//    */
//   isNoPass(num: number) {
//     if (num < this._option.min) {
//       return 1;
//     }
//     const max = this._option.max;
//     if (max && num > max) {
//       return 2;
//     }
//     return 0;
//   }
// }

import { StringHandler } from '@alan-apps/utils';
import {
  Attribute,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const NUMBER_ONLY = new InjectionToken<{ min: number; max?: number }>(
  'NUMBER_ONLY',
);

const EXE_COUNTER_VALUE_ACCESSOR_2: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberOnlyDirective),
  multi: true,
};

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[number-only]',
  providers: [EXE_COUNTER_VALUE_ACCESSOR_2],
  standalone: true,
})
export class NumberOnlyDirective implements ControlValueAccessor {
  @Input()
  get option() {
    return this._option;
  }
  set option(value) {
    this._option = value;
    if (this._elm) {
      this.checkValue(this._elm.nativeElement.value);
    }
  }
  private _option!: { min: number; max?: number };

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') _count: string | number | null = null;
  @Input() disabled?: boolean;

  @Input() whenCheck: 'input' | 'blur' = 'blur';
  @Input() enterCheck = true;

  @Input() float = false;
  @Input() floatFixed = 2;
  @Input() allowZero = false;

  get count() {
    return this._count;
  }
  set count(value: string | number | null) {
    const defaultValue = this.allowZero ? 0 : null;
    this._count = (this.float ? +value! : Math.floor(+value!)) || defaultValue;
    if (this.type === 'number') {
      if (this.float && this._count) {
        this._count = +this._count.toFixed(this.floatFixed);
      }

      this.onChange(this._count);
    } else {
      this.onChange(this._count ? this._count.toString() : defaultValue);
    }
    this._elm.nativeElement.value =
      this._count === 0 ? defaultValue : +this.count!;
  }

  @HostListener('keydown', ['$event', '$event.target.value'])
  keydown(e: KeyboardEvent, value: any) {
    if (this.enterCheck && e.code === 'Enter') {
      this.checkValue(value);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  blur(value: unknown) {
    this.onTouched();
    if (this.whenCheck === 'blur') {
      this.checkValue(value);
    }
  }

  @HostListener('input', ['$event.target.value'])
  change(value: unknown) {
    this.onTouched();
    if (this.whenCheck === 'input') {
      this.checkValue(value);
    }
  }

  private onChange = (_: any) => {
    //
  };
  private onTouched = () => {
    //
  };

  constructor(
    private _elm: ElementRef,
    @Attribute('type') private type: string,
    @Inject(NUMBER_ONLY) public data: { min: number; max?: number },
  ) {
    // console.log(type);
    if (data && !this._option) {
      this._option = data;
    }

    // if (this.isNoPass(this.count)) {
    //   this.count = this.option.min || 0;
    // }
  }

  private checkValue(e: any) {
    if (e === null || e === '') {
      this.count = this._option.min || null;
    } else if (new StringHandler(e).isNumeric()) {
      switch (this.isNoPass(+e)) {
        case 1:
          this.count = this._option.min || null;
          break;
        case 2:
          this.count = this._option.max || null;
          break;
        case 0:
          this.count = e;
          break;
      }
    }
    if (this.count === 0) {
      this._elm.nativeElement.value = null;
    } else {
      this._elm.nativeElement.value = +this.count! || null;
    }
  }

  writeValue(value: any) {
    this.count = value;
  }
  registerOnChange(fn: (value: any) => any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * check number is in range
   * @param num number
   */
  isNoPass(num: number) {
    if (num < this._option.min) {
      return 1;
    }
    const max = this._option.max;
    if (max && num > max) {
      return 2;
    }
    return 0;
  }
}
