import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

export type RolePermissionFn = (_auth: AuthService, _router: Router) => boolean;

export const roleGuard: CanActivateFn = (route) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const roleCheckFn = route.data['roleCheckFn'] as RolePermissionFn;

  const result = roleCheckFn?.(auth, router);

  if (result) return true;

  return router.parseUrl('/no-permissions');
};
