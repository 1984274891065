import { AbstractControl } from '@angular/forms';

export class UserValidators {
  static password(control: AbstractControl) {
    if (
      !(control.value as string)?.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_+=]{8,}$/g,
      )
    ) {
      return { password: true };
    }
    return null;
  }

  static hasNumber(control: AbstractControl) {
    if (!(control.value as string)?.match(/^(?=.*\d).{1,}$/)) {
      return { hasNumber: true };
    }
    return null;
  }

  static hasUpperAlphabet(control: AbstractControl) {
    if (!(control.value as string)?.match(/([A-Z]).{0,}$/)) {
      return { hasUpperAlphabet: true };
    }
    return null;
  }

  static hasAlphabet(control: AbstractControl) {
    if (!(control.value as string)?.match(/([a-zA-Z]).{0,}$/)) {
      return { hasAlphabet: true };
    }
    return null;
  }

  static noSpecial(control: AbstractControl) {
    if (!(control.value as string)?.match(/^[A-Za-z\d!@#$%^&*()+=]{0,}$/)) {
      return { noSpecial: true };
    }
    return null;
  }
}
