export enum ROLES {
  NON_LOGIN = 0, // 未登入
  GENERAL = 1, // 一般使用者
  MEMBER = 500, // 經理人
  ADVANCE = 800, // 秘書
  MANAGER = 900, // 營業處管理員
  READONLY = 998, // 唯讀
  ADMIN = 999,
  SYSTEM = 10000,
}

export const ALL_MEMBER = [
  ROLES.MEMBER,
  ROLES.ADVANCE,
  ROLES.MANAGER,
  ROLES.ADMIN,
  ROLES.SYSTEM,
];

export const ALL_USER = [ROLES.GENERAL, ...ALL_MEMBER];
