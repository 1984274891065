import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { TOKEN_KEY } from '@alan-apps/api-interfaces';

import { AuthService, I18nService } from '../services';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private _auth = inject(AuthService);
  private _i18n = inject(I18nService);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.startsWith('/')) {
      return next.handle(req);
    }

    const lang = this._i18n.language;

    const setHeaders: Record<string, string> = {};

    const token = this._auth.token;

    if (token) setHeaders['Authorization'] = `${TOKEN_KEY} ${token}`;

    if (lang) setHeaders['lang'] = lang;

    const serverReq = req.clone({
      setHeaders,
    });

    return next.handle(serverReq);
  }
}
