import { Pipe, PipeTransform } from '@angular/core';

const step = 1024;
export const FILE_SIZE_UNIT = {
  KB: step,
  get MB() {
    return this.KB * this.KB;
  },
  get GB() {
    return this.MB * this.KB;
  },
};

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, extension: keyof typeof FILE_SIZE_UNIT = 'MB') {
    const value = size / FILE_SIZE_UNIT[extension];
    return (value % 1 === 0 ? value : value.toFixed(2)) + extension;
  }
}
