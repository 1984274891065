import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  constructor(private _snackBar: MatSnackBar) {}

  delete(title: string) {
    return of(true).pipe(
      tap(() => {
        this._snackBar.open(`${title} 刪除成功`, undefined, {
          duration: 1500,
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
        });
      }),
    );
  }

  show(title: string) {
    return of(true).pipe(this.showInfo(title));
  }

  close() {
    this._snackBar.dismiss();
  }

  saveSuccess<T extends Observable<any>>(obs$: T, title = '儲存成功') {
    return obs$.pipe(this.showInfo(title, 2500)) as T;
  }

  deleteSuccess<T extends Observable<any>>(obs$: T) {
    return obs$.pipe(this.showInfo('成功刪除', 2500)) as T;
  }

  private showInfo(title: string, duration = 1500) {
    return tap(() => {
      this._snackBar.open(title, undefined, {
        duration,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    });
  }
}
