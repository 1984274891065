/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { BlockViewOptions, BlockViewService } from '../components';

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  constructor(private _block: BlockViewService) {}

  /**
   * auto filter apollo service result
   */
  apollo<
    T extends any,
    Q extends Omit<
      // @ts-ignore
      Parameters<Parameters<T['forEach']>[0]>[0]['data'],
      '__typename'
    >,
  >(
    queryService: T,
    {
      blockView = true,
      ...options
    }: { blockView?: boolean } & BlockViewOptions = {},
  ): Observable<Q[keyof Q]> {
    const obs$ = blockView
      ? this._block.next(queryService as any, options)
      : this._block.noBlockNext(queryService as any);

    return obs$.pipe(
      map((res: any) => {
        const keys = Object.keys(res.data);

        if (keys.length === 1) {
          return res.data[keys[0]];
        }

        if (keys.length > 2) {
          throw new Error('more than one keys');
        }

        const key = keys.find((x) => x !== '__typename')!;

        return res.data[key];
      }),
    );
  }
}
