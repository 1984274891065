export const ROOT_DEP_ID = '00000';
export const DEFAULT_DEP_ID = '00001';
export const ROOT_DEP_IDS = [ROOT_DEP_ID, DEFAULT_DEP_ID];
export const TOKEN_KEY = 'bearer';

export const isBelongRootDep = (depId: string) =>
  depId && ROOT_DEP_IDS.includes(depId);

export function getRandomString() {
  return Math.random().toString(35).substring(2);
}

export const defaultI18nLang = 'zh-tw';
export const websiteLanguages = [
  {
    id: defaultI18nLang,
    title: '繁體中文',
    icon: 'tw',
  },
  {
    id: 'zh',
    title: '简体中文',
    icon: 'cn',
  },
  {
    id: 'ja',
    title: '日本語',
    icon: 'jp',
  },
  {
    id: 'en',
    title: 'English',
    icon: 'us',
  },
];

export const defaultCacheKey = 'default';

export const acceptLangMap = new Set(['en', 'ja', 'zh-tw', 'zh']);

export const GCP_DIRECTLY_ID = 'GCP_DIRECTLY';

export const SYSTEM_PROPERTY_FILES_ID = 'property-files';

export type SseEventType = 'property-review' | 'property-contract';
