import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
} from 'rxjs';

import { fromPrevious } from '../utils';

function defaultGetCheckedKey<T>(curr: T) {
  return (curr as any)['id'];
}

type SelectionListMap = Record<string, boolean>;

/**
 * common list selection, let you can easy select multiple items once easily.
 */
export class SelectionList<T> {
  private lastCheckedId$ = new BehaviorSubject<string | null>(null);
  private _enabled$ = new BehaviorSubject(false);
  checkedIdsMap$ = new BehaviorSubject<SelectionListMap>({});

  get dataSource$() {
    return this.options.dataSource$;
  }
  get totalCount$() {
    return this.options.totalCount$ || of(null);
  }
  get getCheckedKey() {
    return this.options.getCheckedKey ?? defaultGetCheckedKey;
  }
  get enabled() {
    return this._enabled$.value;
  }
  get checkedIdsMap() {
    return this.checkedIdsMap$.value;
  }

  readonly enabled$ = this._enabled$
    .asObservable()
    .pipe(distinctUntilChanged());

  get checkedIds() {
    return Object.entries(this.checkedIdsMap).reduce<string[]>((acc, curr) => {
      const [key, value] = curr;
      if (value) {
        acc.push(key);
      }

      return acc;
    }, []);
  }

  readonly checkedIds$ = this.checkedIdsMap$.pipe(
    map(() => this.checkedIds),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly currentCheckedCount$ = combineLatest([
    this.checkedIdsMap$,
    this.dataSource$,
  ]).pipe(
    map(([idMap, dataSource]) => {
      const checkedItems = dataSource.filter((data) => {
        const id = this.getCheckedKey(data);
        return idMap[id];
      });

      return checkedItems.length;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly all = {
    checked$: this.currentCheckedCount$.pipe(
      map((x) => x > 0),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ),
    indeterminate$: combineLatest([
      this.currentCheckedCount$,
      this.dataSource$,
    ]).pipe(
      map(([checkedCount, dataSource]) => {
        const total = dataSource.length;

        return checkedCount > 0 && total !== checkedCount;
      }),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ),
  };

  previousDataSource$ = fromPrevious(this.dataSource$).pipe(shareReplay(1));

  prev: any;

  allChecked$ = combineLatest([
    this.all.checked$,
    this.all.indeterminate$,
  ]).pipe(
    debounceTime(0),
    map(([checked, indeterminate]) => checked && !indeterminate),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  constructor(
    private options: {
      dataSource$: Observable<T[]>;
      totalCount$?: Observable<number>;
      keepAllChecked?: boolean;
      /**
       * getter of that map key
       * @default (item) => item.id
       */
      getCheckedKey?: (curr: T) => string;
    },
  ) {}

  toggleCheckedMode() {
    if (this.enabled) {
      this.leaveCheckedMode();
      return this.enabled;
    }

    this.intoCheckedMode();
    return this.enabled;
  }

  intoCheckedMode() {
    this._enabled$.next(true);
  }

  /**
   * @returns is that have set any state
   */
  leaveCheckedMode() {
    this._enabled$.next(false);

    if (Object.keys(this.checkedIdsMap).length > 0) {
      this.clearAllCheckedIdsMap();
    }
  }

  toggleChecked(id: string, state?: boolean) {
    this.lastCheckedId$.next(id);
    this.checkedIdsMap$.next({
      ...this.checkedIdsMap,
      [id]: state ?? !this.checkedIdsMap[id],
    });
  }

  async shiftToggleChecked(id: string) {
    const lastCheckedId = this.lastCheckedId$.value;

    if (lastCheckedId) {
      const items = await firstValueFrom(this.dataSource$);

      const toIndex = items.findIndex(
        (item) => this.getCheckedKey(item) === id,
      );

      const fromIndex = items.findIndex(
        (item) => this.getCheckedKey(item) === lastCheckedId,
      );

      const selectItems = items.slice(
        Math.min(fromIndex, toIndex) + 1,
        Math.max(fromIndex, toIndex),
      );
      this._addAllCheckedIdsMap(selectItems, true);
    }
  }

  /**
   * multiple select behaviors can view here https://react-spectrum.adobe.com/react-aria/useTable.html#multiple-selection
   */
  async selectAll(items: T[]) {
    if (
      (await firstValueFrom(this.all.indeterminate$)) ||
      !(await firstValueFrom(this.all.checked$))
    ) {
      this._addAllCheckedIdsMap(items);
    } else {
      this.clearCurrentAllCheckedIdsMap();
    }
  }

  private getCheckedMap(items: T[], toggleCurr = false) {
    const checkedIdsMap = this.checkedIdsMap;

    return items.reduce((acc, curr) => {
      const id = this.getCheckedKey(curr);

      if (toggleCurr) {
        acc[id] = !checkedIdsMap[id];
      } else {
        acc[id] = true;
      }

      return acc;
    }, {} as SelectionListMap);
  }

  private _addAllCheckedIdsMap(items: T[], toggleCurr = false) {
    const selectAllMap = this.getCheckedMap(items, toggleCurr);

    this.checkedIdsMap$.next({
      ...this.checkedIdsMap,
      ...selectAllMap,
    });
  }

  private async clearCurrentAllCheckedIdsMap() {
    const checkedIdsMap = this.checkedIdsMap;

    const currentDataList = await firstValueFrom(this.dataSource$);

    currentDataList.forEach((curr) => {
      const id = this.getCheckedKey(curr);
      delete checkedIdsMap[id];
    });

    this.checkedIdsMap$.next(checkedIdsMap);
  }

  private async clearAllCheckedIdsMap() {
    this.checkedIdsMap$.next({});
  }
}
