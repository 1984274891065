@if (interactive()) {
  @if (apiLoaded$ | async) {
    @if (centerPosition !== null) {
      <google-map [options]="options" height="inherit" width="inherit">
        <map-marker
          #marker="mapMarker"
          [position]="centerPosition"
          (mapClick)="openInfoWindow(marker)"
        >
        </map-marker>
        @for (near of nearsPositions; track near; let i = $index) {
          <map-marker
            #nearMarker="mapMarker"
            [position]="near"
            (mapClick)="openInfoWindow(nearMarker, i)"
          >
          </map-marker>
        }
        <map-info-window>
          <ng-container
            *ngTemplateOutlet="
              infoTemplateRef;
              context: {
                $implicit: infoProperty
              }
            "
          >
          </ng-container>
        </map-info-window>
      </google-map>
    } @else {
      <img class="w-full border border-primary" src="/assets/img/no_item.jpg" />
    }
  }
} @else {
  @if (staticUrl) {
    <div
      class="cursor-pointer relative inline-block map"
      [class.cursor-pointer]="!disableController()"
    >
      <img [src]="staticUrl | safe: 'url'" />
      @if (!disableController()) {
        <div
          class="mask absolute left-0 top-0 w-full h-full bg-black/12 justify-center items-center"
          (click)="interactive.set(true)"
        >
          <h4>
            {{ '點擊進入操作模式' | translate }}
          </h4>
        </div>
      }
    </div>
  }
}
