import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';

import {
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
  take,
} from 'rxjs';

import { CdkService } from '@nghedgehog/core';

import { NgxRxModalComponent } from './ngx-rx-modal.component';
import {
  ModalComponentType,
  NGX_RX_MODAL_TOKEN,
  NgxRxModalInjectModel,
  NgxRxModalOption,
} from './ngx-rx-modal.model';
import { PathService } from './path.service';

@Injectable({
  providedIn: 'root',
})
export class NgxRxModalService {
  modals: NgxRxModalInjectModel[] = [];
  constructor(
    private _cdk: CdkService,
    private _path: PathService,
  ) {}

  open(
    component: ModalComponentType,
    option: NgxRxModalOption = {} as any,
  ): Observable<any> {
    return this.openWithoutWatchComplete(component, option).pipe(
      switchMap(([, operators]) => operators),
    );
  }

  /**
   * give ability to get that current modal instance, and can control that instance by yourself
   */
  openWithoutWatchComplete(
    component: ModalComponentType,
    option: NgxRxModalOption = {} as any,
  ) {
    return of(null).pipe(
      switchMap(() => {
        const portalHost = this._cdk.createBodyPortalHost();

        const id = this._path.add(
          option.title || '',
          !!option.noRedirect,
          option.redirectURL,
        );

        return getResolveObs(option).pipe(
          map((data) => {
            const isAtTop = (item: NgxRxModalInjectModel) => {
              return item === this.modals.slice(-1)[0];
            };

            let componentRef: ComponentRef<NgxRxModalComponent> = null as any;

            const modalData: NgxRxModalInjectModel = {
              portalHost,
              component,
              option: {
                ...option,
                data: {
                  ...option.data,
                  ...data,
                },
              },
              get atTop() {
                return isAtTop(modalData);
              },
              id,
              get componentRef() {
                return componentRef;
              },
            };

            componentRef = portalHost.attach(
              new ComponentPortal(
                NgxRxModalComponent,
                undefined,
                this._cdk.createInjector(NGX_RX_MODAL_TOKEN, modalData),
              ),
            );

            this.modals.push(modalData);

            const watchComplete = of(modalData).pipe(
              this.watchComplete(option),
            );

            return [modalData, watchComplete] as const;
          }),
        );
      }),
    );
  }

  private watchComplete(
    option: NgxRxModalOption = {} as any,
  ): OperatorFunction<NgxRxModalInjectModel, any> {
    return (obs) =>
      obs.pipe(
        switchMap((modalData) => {
          return modalData.componentRef.instance.completeSubject
            .asObservable()
            .pipe(
              switchMap(([data, isBack]) => {
                return this._path
                  .remove(modalData.id, isBack, !!option.noRedirect)
                  .pipe(map(() => data));
              }),
            );
        }),
        take(1),
        finalize(() => this.modals.pop()),
      );
  }
}

function getResolveObs(option: NgxRxModalOption) {
  return option.resolve ? forkJoin(option.resolve) : of({});
}
