import { A11yModule } from '@angular/cdk/a11y';

import { Component, Inject, InjectionToken, Optional } from '@angular/core';

export const BLOCK_VIEW_TOKEN = new InjectionToken('BLOCK_VIEW_TOKEN');

@Component({
  selector: 'ngx-block-view',
  templateUrl: './block-view.component.html',
  styleUrls: ['./block-view.component.scss'],
  standalone: true,
  imports: [A11yModule],
})
export class BlockViewComponent {
  constructor(
    @Optional()
    @Inject(BLOCK_VIEW_TOKEN)
    public title: string,
  ) {}
}
