import { inject } from '@angular/core';

import { Observable } from 'rxjs';

import { CacheOptions, CacheService } from '../services/cache.service';
import { appGlobal } from '../constants';

declare const ngDevMode: boolean | undefined;

export interface ICache {
  /**
   * current key for cache save
   */
  readonly storageKey: string;
}
/**
 * cache observable with decorator
 */
export const cache =
  (options?: CacheOptions) =>
  (
    target: any,
    key: string,
    descriptor: TypedPropertyDescriptor<(...args: any) => Observable<any>>,
  ) => {
    const fn = descriptor.value as any;

    descriptor.value = function (this: ICache, ...args: any) {
      const cacheService = appGlobal.injector.get(CacheService);

      const obs$: Observable<any> = fn.apply(this, args);

      const argString = JSON.stringify(args);

      const storageKey = this.storageKey ?? target.constructor.name;

      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        if (!this.storageKey) {
          console.warn(
            `[cache] (${target.constructor.name}): should provide storageKey`,
          );
        }
      }

      const cacheKey = `${storageKey}.${key}.${argString}`;

      return cacheService.cache(obs$, cacheKey, options);
    };

    return descriptor;
  };
