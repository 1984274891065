import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';

import { defaultI18nLang } from '@alan-apps/api-interfaces';
import { TranslateLoader } from '@ngx-translate/core';

import { cache, ICache } from '../../decorators/cache.decorator';

export class TranslateHttpLoader implements TranslateLoader, ICache {
  readonly storageKey = 'TranslateHttpLoader';

  constructor(private http: HttpClient, public prefix: string) {}

  public getTranslation(lang: string) {
    if (lang === defaultI18nLang) {
      return of({});
    }

    return this.fetchLang(lang);
  }

  @cache({ withoutLocale: true })
  private fetchLang(lang: string) {
    return this.http.get(`${this.prefix}${lang}`);
  }
}

export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/api/i18n/');
}
