import { inject, Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';

import { BehaviorSubject, distinctUntilChanged, filter, skip, tap } from 'rxjs';

import {
  acceptLangMap,
  defaultI18nLang,
  websiteLanguages,
} from '@alan-apps/api-interfaces';
import { TranslateService } from '@ngx-translate/core';

import { storage } from '../decorators';
import { ICache } from '../decorators/cache.decorator';
import {
  clearI18nString,
  getI18nRouteLink,
  getI18nRouteLinkString,
} from '../pipes';
import { DocumentHeaderService } from './document-header.service';
import { BaseService } from './base.service';

export interface Language {
  id: string;
  title: string;
  icon: string;
}

interface SetI18nProps {
  reload?: boolean;
  force?: boolean;
}

const ogLangMap: Record<string, string> = {
  en: 'en_US',
  zh: 'zh_CN',
  'zh-tw': 'zh_TW',
  ja: 'ja_JP',
};

@Injectable({
  providedIn: 'root',
})
export class I18nService implements ICache {
  private base = inject(BaseService);
  public translate = inject(TranslateService);
  private router = inject(Router);
  private documentHeader = inject(DocumentHeaderService);
  readonly storageKey = 'I18nService';

  @storage()
  detected = false;

  languages: Language[] = websiteLanguages;

  language$ = new BehaviorSubject<string | null>(null);

  get language() {
    return this.language$.value;
  }

  languageChange$ = this.language$.asObservable().pipe(
    skip(1),
    distinctUntilChanged((a, b) => a !== b),
  );

  constructor() {
    const upsertHrefLangs = () => {
      return this.languages.map((lang) => {
        this.documentHeader.upsertHrefLang(lang);
        return lang.id;
      });
    };

    const langs = upsertHrefLangs();
    this.translate.addLangs(langs);
    this.translate.setDefaultLang(langs[0]);

    this.router.events
      .pipe(
        skip(1),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        tap(() => upsertHrefLangs()),
      )
      .subscribe();
  }

  detectLanguage() {
    // when be bot, use default language
    const isBot = this.base.isBot;

    if (isBot) return defaultI18nLang;

    const browserLang = this.translate.getBrowserLang();
    console.log('browserLang', browserLang);

    if (browserLang) {
      if (browserLang.includes('zh')) {
        return defaultI18nLang;
      }

      const accept = acceptLangMap.has(browserLang);

      if (accept) {
        return browserLang;
      }
    }

    return defaultI18nLang;
  }

  setLang(lang: string, { reload = false, force = false }: SetI18nProps = {}) {
    const accept = acceptLangMap.has(lang);

    if (!accept || (!force && this.language === lang)) return;

    this.language$.next(lang);
    this.translate.use(lang);

    const ogLang: string = ogLangMap[lang] || ogLangMap['zh-tw'];
    this.documentHeader.updateHeaders(ogLang);

    if (reload) {
      const url = this.router.url;
      this.router
        .navigateByUrl('/reload', { skipLocationChange: true })
        .then(() => {
          const toRoute = getI18nRouteLinkString([clearI18nString(url)], lang);

          this.router.navigateByUrl(toRoute);
        });
    }
  }
}

export const useI18nRouter = () => {
  const i18n = inject(I18nService);
  const router = inject(Router);

  const getI18nUrl = (commands: string[]) =>
    getI18nRouteLinkString(commands, i18n.language);

  return {
    get url() {
      return router.url;
    },
    get events() {
      return router.events;
    },
    getI18nUrl,
    navigate: (commands: string[], extras?: NavigationExtras) => {
      if (extras) {
        const toCommands = getI18nRouteLink(commands, i18n.language);
        return router.navigate(toCommands, extras);
      }

      const toCommands = getI18nUrl(commands);
      return router.navigateByUrl(toCommands, extras);
    },
  };
};
