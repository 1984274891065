import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { APP_URL } from '../constants';
import { AuthService, PathService } from '../services';

export const AuthGuard: CanActivateFn = (_, state) => {
  const url = inject(APP_URL);
  const auth = inject(AuthService);
  const path = inject(PathService);

  const user = auth.user;

  if (user?.id) return true;

  path.saveGo(url.nonAuthentication, state.url);
  return false;
};
