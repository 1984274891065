const bots = [
  'google-inspectiontool',
  'googlebot',
  'baiduspider',
  'bingbot',
  'embedly',
  'facebookexternalhit',
  'linkedinbot',
  'outbrain',
  'pinterest',
  'quora link preview',
  'rogerbot',
  'showyoubot',
  'slackbot',
  'telegrambot',
  'twitterbot',
  'vkshare',
  'w3c_validator',
  'googlebot',
  'whatsapp', // 'WhatsApp',
];

export function detectBot(userAgent: string, additionBot: string[] = []) {
  if (userAgent) {
    const agent = userAgent.toLocaleLowerCase();
    return [...bots, ...additionBot].some((bot) => agent.indexOf(bot) > -1);
  }
  return false;
}
