import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { catchError, map, Observable, of, shareReplay } from 'rxjs';

import { defaultI18nLang } from '@alan-apps/api-interfaces';
import { GEOCODING_KEY } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private key = inject(GEOCODING_KEY);
  private language: string = defaultI18nLang;
  private loadMapScriptMap: Record<string, Observable<boolean> | undefined> =
    {};
  constructor(private _http: HttpClient) {}

  getLoadMapScript(language: string = defaultI18nLang) {
    // if language change, reset that observable for not shareReplay
    if (this.language !== language) {
      this.loadMapScriptMap[language] = undefined;
    }

    if (!this.loadMapScriptMap[language]) {
      this.clearGlobalGoogleMapInstance();

      this.loadMapScriptMap[language] = this._http
        .jsonp(
          `https://maps.googleapis.com/maps/api/js?${new HttpParams({
            fromObject: {
              key: this.key,
              language,
            },
          }).toString()}`,
          'callback',
        )
        .pipe(
          map(() => true),
          catchError((e) => {
            console.error(e);
            return of(false);
          }),
          shareReplay(1),
        );
    }

    this.language = language;

    return this.loadMapScriptMap[language]!;
  }

  private clearGlobalGoogleMapInstance() {
    (window as any)['google'] = {};
    (window as any)['google']['maps'] = {};
  }
}
