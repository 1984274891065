import { FocusableOption } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { input } from '@angular/core';

@Directive({
  selector: '[auto-focus]',
  standalone: true,
})
export class AutofocusDirective implements FocusableOption, AfterViewInit {
  type = input<boolean | 'select'>(true, { alias: 'auto-focus' });
  focusTime = input(0);

  constructor(private _elm: ElementRef) {}

  focus(): void {
    this._elm.nativeElement.focus();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._elm.nativeElement.focus();
      if (this.type() === 'select') {
        this._elm.nativeElement.select();
      }
    }, this.focusTime());
  }
}
