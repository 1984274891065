<ol class="flex flex-wrap items-center">
  @if (!noHome()) {
    <li>
      <a [routerLink]="['/'] | i18nLink">{{ '首頁' | translate }}</a>
    </li>
  }
  @for (link of links; track link) {
    <li [attr.aria-disabled]="link.disabled">
      <a
        [routerLink]="link.url | i18nLink"
        [attr.aria-disabled]="link.disabled"
        [ngClass]="{ 'pointer-events-none': link.disabled }"
        [queryParams]="link.queryParams"
      >
        {{ link.title | translate }}
      </a>
    </li>
  }
</ol>
