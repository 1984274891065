import { inject, Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../../services/i18n.service';
import { getI18nRouteLink } from './getI18nRouteLink';

@Pipe({
  name: 'i18nLink',
  standalone: true,
})
export class I18nLinkPipe implements PipeTransform {
  i18n = inject(I18nService);

  public transform(commands: any[] | string | null | undefined) {
    const link = getI18nRouteLink(commands, this.i18n.language);
    return link;
  }
}
