import { cityField } from './city.field';
import { fileField } from './file.field';

export const propertyListItemField = `
id,
name,
type,
featured,
price,
photo{
  file{
    ${fileField}
  },
},
age,
body_short,
spaceNumber,
mainSpaceNumber,
patterns{
  amount,
  type
},
category{
  id,
  name
},
${cityField}
`;
// fullAddress,
