import { DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CdkService {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _appRef: ApplicationRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
  ) {}

  createBodyPortalHost() {
    // TODO: wait material-cdk to fix this
    return new DomPortalOutlet(
      this.document.body,
      this._componentFactoryResolver,
      this._appRef,
      this._injector,
    );
  }

  createInjector<T>(key: InjectionToken<T>, data: T) {
    return Injector.create({
      providers: [
        {
          provide: key,
          useValue: data,
        },
      ],
    });
  }
}
