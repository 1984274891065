import { DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  Optional,
  Renderer2,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { AutoDestroy, ElementHandler } from '@nghedgehog/core';

import { allAnimation } from './animation';
import { CloseComponent } from './close/close.component';
import {
  ModalComponentType,
  NGX_RX_MODAL_ADDITION_CHECK_ON_ESC,
  NGX_RX_MODAL_CLASSES,
  NGX_RX_MODAL_CLOSE,
  NGX_RX_MODAL_ELEMENT,
  NGX_RX_MODAL_MODE,
  NGX_RX_MODAL_TOKEN,
  NgxRxModalClasses,
  NgxRxModalInjectModel,
  NgxRxModalOption,
  NgxRxModalRef,
} from './ngx-rx-modal.model';
import { PathService } from './path.service';
import { ViewContainerDirective } from './view-container.directive';

@Component({
  selector: 'ngx-rx-modal',
  templateUrl: './ngx-rx-modal.component.html',
  styleUrls: ['./ngx-rx-modal.component.scss'],
  animations: [allAnimation],
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, ViewContainerDirective],
})
export class NgxRxModalComponent
  extends AutoDestroy
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  @HostBinding('@animate') animate = 'fadeIn';

  @ViewChild('panel', { static: true }) panel!: ElementRef<HTMLDivElement>;
  @ViewChild('mainElm', { read: ViewContainerDirective, static: true })
  view!: ViewContainerDirective;
  @ViewChild('closeElm', { read: ViewContainerDirective })
  closeView!: ViewContainerDirective;

  portalHost: DomPortalOutlet = this._injectData.portalHost;
  component: ModalComponentType = this._injectData.component;
  componentTemplateRef: TemplateRef<any> | null = null;

  option: NgxRxModalOption = this._injectData.option;
  id: string = this._injectData.id;
  completeSubject: Subject<any> = new Subject();
  isTemplate = true;

  completeEmitter?: EventEmitter<string | undefined>;

  private sendData: any;
  private isBack = true;
  private closeRef!: ChangeDetectorRef;

  private componentRef!: ComponentRef<NgxRxModalRef>;

  elementHandler = new ElementHandler(this._renderer, this.document);

  @HostListener('window:popstate', ['$event'])
  onPopstate(event: any) {
    // if there isn't redirectURL, detach modal
    if (this._path.check(this.id)) {
      this.isBack = false;
      this.portalHost.detach();
    }
  }

  @HostListener('document:keydown.escape')
  async onKeydownHandler() {
    if (
      !this._injectData?.option.disableClose &&
      this._injectData.atTop &&
      this.additionCheckOnEsc()
    ) {
      this.close();
    }
  }

  get rootElm() {
    return this._elm.nativeElement;
  }

  constructor(
    @Inject(NGX_RX_MODAL_TOKEN) private _injectData: NgxRxModalInjectModel,
    @Optional()
    @Inject(NGX_RX_MODAL_CLASSES)
    public classes: NgxRxModalClasses | null = {},
    @Optional() @Inject(NGX_RX_MODAL_CLOSE) private closeComponent: any,
    @Optional()
    @Inject(NGX_RX_MODAL_ADDITION_CHECK_ON_ESC)
    private additionCheckOnEsc: () => boolean = () => true,
    @Inject(DOCUMENT) private document: any,
    private _elm: ElementRef<HTMLDivElement>,
    private _renderer: Renderer2,
    private _path: PathService,
  ) {
    super();

    const keys = Object.keys(_injectData.option.data || {});

    if (keys.length === 0) {
      _injectData.option.data = undefined;
    }
  }

  @HostListener('@animate.done', ['$event'])
  animateDone(event: any) {
    if (event.toState === 'void') {
      this.completeSubject.next([this.sendData, this.isBack]);
    }
  }

  ngAfterContentInit() {
    if (this.component instanceof Type) {
      this.isTemplate = false;
      this.loadComponent(this.component);
    } else {
      this.componentTemplateRef = this.component;
      this.completeEmitter = new EventEmitter<string | undefined>();

      this.completeEmitter.pipe(takeUntil(this._destroy$)).subscribe((data) => {
        this.sendData = data;
        this.portalHost.detach();
      });
    }
    this.handelStyle(this.option);
  }

  ngAfterViewInit(): void {
    this.setViewScroll();
    this.loadCloseElm();

    if (this.option.fixedContainer) {
      const panelElm = this.panel.nativeElement;
      const popElm = this.rootElm;

      const elmDetail = {
        height: panelElm.clientHeight,
        width: panelElm.clientWidth,
        top: panelElm.offsetTop,
        left: panelElm.offsetLeft,
      };

      const windowElm = {
        height: popElm.offsetHeight,
        width: popElm.offsetWidth,
      };

      const heightDis = elmDetail.top + elmDetail.height - windowElm.height;
      const widthDis = elmDetail.left + elmDetail.width - windowElm.width;

      if (heightDis > 0) {
        this._renderer.setStyle(
          this.panel.nativeElement,
          'top',
          `${windowElm.height - elmDetail.height}px`,
        );
      }
      if (widthDis > 0) {
        this._renderer.setStyle(
          this.panel.nativeElement,
          'left',
          `${windowElm.width - elmDetail.width}px`,
        );
      }
    }
  }

  override ngOnDestroy() {
    // https://stackoverflow.com/questions/42387348/angular2-dynamic-content-loading-throws-expression-changed-exception
    if (this.closeRef) {
      this.closeRef.detach();
    }
  }

  // handel the pop-up style and class
  private handelStyle(config: NgxRxModalOption = {}) {
    if (config.hiddenOpen) {
      // this.elementHandler.addClassByString(this.rootElm, HIDDEN_CLASS);
      this._renderer.addClass(this.rootElm, 'opacity-0');
      this._renderer.addClass(this.rootElm, 'pointer-events-none');
    }

    if (config.backdropClass) {
      this.elementHandler.addClassByString(this.rootElm, config.backdropClass);
    }

    this.elementHandler.addStyles(this.rootElm, config.backdropStyle);

    if (!config.notMdFix) {
      this._renderer.addClass(this.rootElm, 'md-fix');
    }

    this.elementHandler.addStyles(this.panel.nativeElement, config.panelStyle);

    this.elementHandler.addClassByString(
      this.panel.nativeElement,
      config.panelClass || 'bg-white',
    );

    if (!config.elevation) {
      config.elevation = 24;
    }
    this._renderer.addClass(
      this.panel.nativeElement,
      `elevation-${config.elevation}`,
    );
  }

  // load Dynamic component
  private loadComponent(component: Type<any>) {
    const viewContainerRef = this.view.viewContainerRef;

    const injector = Injector.create({
      providers: [
        {
          provide: NGX_RX_MODAL_TOKEN,
          useValue: this.option.data,
        },
        /**
         * when use modal to open that component, must have modal mode as true
         */
        {
          provide: NGX_RX_MODAL_MODE,
          useValue: true,
        },
        {
          provide: NGX_RX_MODAL_ELEMENT,
          useValue: this.rootElm,
        },
      ],
    });

    this.componentRef = viewContainerRef.createComponent<NgxRxModalRef>(
      component,
      {
        index: 0,
        injector,
      },
    );

    if (!this.componentRef.instance.complete) {
      this.componentRef.instance.complete = new Subject();
    }

    this.componentRef.instance.complete
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.sendData = data;
        this.portalHost.detach();
      });

    this.componentRef.instance.showHidden$
      ?.pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._renderer.removeClass(this.rootElm, 'opacity-0');
        this._renderer.removeClass(this.rootElm, 'pointer-events-none');
      });
  }

  private loadCloseElm() {
    if (!this.option.disableCloseButton) {
      const viewContainerRef = this.closeView.viewContainerRef;
      this.closeRef = viewContainerRef.createComponent(
        this.closeComponent || CloseComponent,
      ).changeDetectorRef;

      this.closeRef.detectChanges();
    }
  }

  async close() {
    const result = await this.option.onClose?.();

    if (this.component instanceof Type) {
      const instance = this.componentRef.instance;
      instance.complete.next(instance.closeEmitValue || result);
    } else {
      this.completeEmitter?.next(result);
    }
  }

  private setViewScroll() {
    const css = `body {
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      height: 100%;
      width: ${this.document.body.clientWidth}px;
    }
    `;
    const styleText = this._renderer.createElement('style');
    this._renderer.setAttribute(styleText, 'type', 'text/css');
    this._renderer.appendChild(styleText, this._renderer.createText(css));
    this._renderer.appendChild(this.rootElm, styleText);
  }
}
