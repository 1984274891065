import { animate, animation, keyframes, style } from '@angular/animations';

export const animationTime = 195;
export const timing195ms = `${animationTime}ms cubic-bezier(0.4, 0.0, 0.6, 1)`;

export const fadeIn = animation(
  [style({ opacity: 0 }), animate('{{delay}}', style({ opacity: 1 }))],
  { params: { delay: timing195ms } },
);

export const fadeOut = animation(
  [style({ opacity: 1 }), animate('{{delay}}', style({ opacity: 0 }))],
  {
    params: { delay: timing195ms },
  },
);

export const bounceInDown = animation([
  animate(
    timing195ms,
    keyframes([
      style({
        opacity: 0,
        transform: 'translate3d(0, -1000px, 0)',
        offset: 0,
      }),
      style({
        opacity: 1,
        transform: 'translate3d(0, 20px, 0)',
        offset: 0.6,
      }),
      style({ transform: 'translate3d(0, -10px, 0)', offset: 0.75 }),
      style({ transform: 'translate3d(0, 5px, 0)', offset: 0.9 }),
      style({ transform: 'translate3d(0, 0, 0)', offset: 1 }),
    ]),
  ),
]);

export const bounceOutDown = animation([
  animate(
    timing195ms,
    keyframes([
      style({ transform: 'translate3d(0, 10px, 0)', offset: 0.2 }),
      style({
        opacity: 1,
        transform: 'translate3d(0, -20px, 0)',
        offset: 0.5,
      }),
      style({
        opacity: 0,
        transform: 'translate3d(0, 1000px, 0)',
        offset: 1,
      }),
    ]),
  ),
]);

export const zoomIn = animation([
  style({ opacity: 0, transform: 'scale3d(.1, .1, .1)' }),
  animate(timing195ms, style({ opacity: 1, transform: 'scale3d(1, 1, 1)' })),
]);

export const zoomOut = animation([
  animate(
    timing195ms,
    keyframes([
      style({ opacity: 1, transform: 'scale3d(1, 1, 1)', offset: 0 }),
      style({ opacity: 0, transform: 'scale3d(.1, .1, .1)', offset: 1 }),
    ]),
  ),
]);

export const slideInRtoL = animation([
  style({ transform: 'translate3d(100%, 0, 0)' }),
  animate(timing195ms, style({ transform: 'translate3d(0, 0, 0)' })),
]);

export const slideInLtoR = animation([
  style({ transform: 'translate3d(0, 0, 0)' }),
  animate(timing195ms, style({ transform: 'translate3d(100%, 0, 0)' })),
]);
