export enum PropertyContractStatus {
  waitConfirm,
  verifying,
  confirm,
  completed,
}

export const PROPERTY_CONTRACT_STATUS = [
  PropertyContractStatus.waitConfirm,
  PropertyContractStatus.verifying,
  PropertyContractStatus.confirm,
  PropertyContractStatus.completed,
];
