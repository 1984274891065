import { Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';

import { filter, tap } from 'rxjs';

import { storage } from '../decorators/storage.decorator';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadErrorService {
  readonly storageKey = 'LazyLoadErrorService';

  @storage()
  reloadByNavigationError = false;

  constructor(private _router: Router) {}

  listen() {
    if (this.reloadByNavigationError) {
      this.reloadByNavigationError = false;

      if (location.pathname !== '/') {
        this._router.navigateByUrl('/');
        this.listenLazyloadFailEvent();
      }
      return;
    }

    // never cancel when app is alive
    this.listenLazyloadFailEvent();
  }

  private listenLazyloadFailEvent() {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationError),
        tap((e) => {
          const event = e as NavigationError;

          this.reloadByNavigationError = true;
          location.href = `${location.origin}${event.url}`;
        }),
      )
      .subscribe();
  }
}
