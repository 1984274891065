import { A11yModule } from '@angular/cdk/a11y';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';

import { NGX_RX_MODAL_TOKEN, NgxRxModalRef } from 'ngx-rx-modal';
import { Subject } from 'rxjs';

import { DIALOG_TYPE, NgxRxAlertModel2 } from './ngx-rx-alert.model';

export interface NgxRxAlertInputData {
  data: NgxRxAlertModel2;
  type: DIALOG_TYPE;
}

@Component({
  selector: 'ngx-rx-alert',
  templateUrl: './ngx-rx-alert.component.html',
  styleUrls: ['./ngx-rx-alert.component.scss'],
  standalone: true,
  imports: [
    A11yModule,
    MatIconModule,
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    MatButtonModule,
  ],
})
export class NgxRxAlertComponent implements NgxRxModalRef, AfterViewInit {
  @ViewChild('okElm', { read: ElementRef, static: true }) okElm!: ElementRef;
  complete = new Subject();

  isRequired = true;

  formGroup: UntypedFormGroup = this._fb.group({
    message: [this.data?.inputProps?.defaultValue ?? '', []],
  });

  get message() {
    return this.formGroup.get('message');
  }

  isConfirm = false;

  get data() {
    return this.source.data?.options;
  }

  get templateRef() {
    return this.source.data?.options.templateRef;
  }

  get type() {
    return this.data?.inputProps?.type || 'string';
  }

  classList = {
    success: {
      icon: 'check_circle', // cycle ˇ
      class: 'text-success',
    },
    warning: {
      icon: 'warning', // triangle !
      class: 'text-danger',
    },
    info: {
      icon: 'info', // cycle !
      class: 'text-accent',
    },
    error: {
      icon: 'highlight_off', // cycle x
      class: 'text-danger',
    },
  };

  @HostListener('document:keydown.escape')
  onKeydownHandler() {
    if (!this.data?.disableClose) {
      this.cancel();
    }
  }

  constructor(
    @Inject(NGX_RX_MODAL_TOKEN) private source: NgxRxAlertInputData,
    private _fb: UntypedFormBuilder,
    private _sanitizer: DomSanitizer,
  ) {
    this.data.message = (
      this.data.message
        ? this._sanitizer.bypassSecurityTrustHtml(this.data.message)
        : ''
    ) as string;

    this.isConfirm = source.type === DIALOG_TYPE.CONFIRM;

    if (this.data.hasInput) {
      this.message!.setValidators(this.data.inputValidator || null);
    }
  }

  ngAfterViewInit(): void {
    if (this.okElm && !this.data.hasInput) {
      setTimeout(() => {
        this.okElm.nativeElement.focus();
      }, 100);
    }
  }

  ok() {
    if (this.data.hasInput) {
      if (this.formGroup.invalid) {
        return;
      }
      return this.complete.next(this.message!.value);
    }
    this.complete.next(true);
  }

  cancel() {
    this.complete.next(false);
  }
}
