import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';

/**
 * Executes the provided callback function only if the code is running in a browser environment.
 * If the code is running on the server-side, it returns a default value.
 * @param cb - The callback function to be executed in a browser environment.
 * @param defaultData - The default value to be returned if the code is running on the server-side.
 * @returns The result of the callback function if running in a browser environment, or the default value if running on the server-side.
 */

export function useOnBrowser<T extends (...args: any[]) => unknown>(
  cb: T,
  defaultData?: unknown,
) {
  const platformId = inject(PLATFORM_ID);
  const isBrowser = isPlatformBrowser(platformId);
  if (isBrowser) return cb;

  return (() => {
    return defaultData;
  }) as T;
}
