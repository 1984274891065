<div
  #panel
  [@animate]="option.windowAnimate"
  [ngClass]="['panel', classes?.panel || '']"
>
  <!-- content -->
  @if (isTemplate) {
    <ng-container
      *ngTemplateOutlet="
        componentTemplateRef;
        context: {
          $implicit: option.data,
          modalMode: true,
          complete: completeEmitter
        }
      "
    ></ng-container>
  }

  <ng-template #mainElm view-container></ng-template>
</div>

<!--background-->
@if (!option.disableBackdropClick) {
  <div
    [ngClass]="['background', classes?.backdrop || '']"
    (click)="close()"
  ></div>
}

<!--close button-->
@if (!option.disableCloseButton) {
  <div
    [@animate]="'slideInRtoL'"
    [ngClass]="['close-action', classes?.close || '']"
    (click)="close()"
  >
    <ng-template #closeElm view-container></ng-template>
  </div>
}
