/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export class SortListHandler<T> {
  enabled = false;

  sortingList: T[] = [];
  outputList: T[] = [];

  get getKey() {
    return this.options.getKey!;
  }

  constructor(
    private options: {
      key?: string;
      onSave: (output: T[], sortingList: T[]) => void | Promise<void>;
      onInit: () => T[] | Promise<T[]>;
      getKey?: (item: T) => string;
    },
  ) {
    options.key ??= 'id';

    options.getKey ??= (item: any) => item['id'];
  }

  async enable() {
    this.sortingList = [...(await this.options.onInit())];

    this.enabled = !this.enabled;
  }

  async save() {
    if (this.enabled && this.outputList.length > 0) {
      await this.options.onSave(
        this.outputList.map((x: any) => {
          return {
            [this.options.key!]: this.getKey(x),
            sort: x['sort'],
          } as any;
        }),
        this.sortingList,
      );
    }

    this.enabled = false;
  }

  cancel() {
    this.sortingList.length = 0;
    this.outputList.length = 0;
    this.enabled = false;
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<any>) {
    const { from, to } = this.moveArrayAndGetFromTo(
      previousIndex,
      currentIndex,
      this.sortingList,
    );

    for (let i = from; i <= to; i++) {
      const item = {
        ...this.sortingList[i],
        sort: i,
      };

      const updateI = this.outputList.findIndex(
        (x) => this.getKey(x) === this.getKey(item),
      );

      if (updateI > -1) {
        this.outputList[updateI] = item;
      } else {
        this.outputList.push(item);
      }
    }
  }

  moveArrayAndGetFromTo(
    previousIndex: number,
    currentIndex: number,
    array: any[],
  ) {
    const isBigger = previousIndex < currentIndex;
    const from = isBigger ? previousIndex : currentIndex;
    const to = isBigger ? currentIndex : previousIndex;
    moveItemInArray(array, previousIndex, currentIndex);
    return { from, to };
  }
}
