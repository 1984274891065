import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  fromEvent,
  map,
  Observable,
  startWith,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  mainScrollTopEvent?: Observable<number>;

  private _mainViewElm!: HTMLElement;

  private _mainScrollTopEvent = new BehaviorSubject<number>(0);

  set mainViewElm(elm) {
    const scrollElm: HTMLElement = (elm as any)['scrollingElement'] as any;

    this.mainScrollTopEvent = (
      scrollElm
        ? fromEvent(elm!, 'scroll').pipe(
            map((e: any) => e.target.scrollingElement.scrollTop),
          )
        : fromEvent(elm!, 'scroll').pipe(map((e: any) => e.target.scrollTop))
    ).pipe(
      startWith(this._mainScrollTopEvent.getValue()),
      tap((scrollTop) => this._mainScrollTopEvent.next(scrollTop)),
    );

    // if the elm is exist unsubscribe prev, and do the next elm observable
    this._mainViewElm = scrollElm ? scrollElm : elm;
  }
  get mainViewElm() {
    return this._mainViewElm;
  }

  setMainView(elm: HTMLElement) {
    this.mainViewElm = elm;
    return this.mainScrollTopEvent;
  }

  scrollTop() {
    this.mainViewElm?.scroll({ top: 0, left: 0, behavior: 'auto' });
  }
}
