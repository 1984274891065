export interface FileContentDetail {
  name: any;
  path: string;
  size: number;
  file: File;
}

export class FileContentReader {
  private _ext?: string;
  private _name?: string;

  constructor(private file: File) {}

  get ext() {
    if (!this._ext) {
      this._ext = this.file.name.split('.').pop();
    }
    return this._ext;
  }

  get name() {
    if (!this._name) {
      this._name = this.file.name.substring(0, this.file.name.lastIndexOf('.'));
    }
    return this._name;
  }

  getText() {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        resolve((<any>evt.target).result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsText(this.file, 'utf-8');
    });
  }

  getDetail() {
    return new Promise<FileContentDetail>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve({
          name: this.file.name,
          path: e.target.result,
          size: e.total,
          file: this.file,
        });
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(this.file);
    });
  }
}
