import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';

import { PageOption } from '@alan-apps/api-interfaces';

import { APP_PAGINATION_DEFAULT_TAKE } from '../constants';

/**
 * Custom hook for handling pagination logic.
 *
 * @param take - The number of items to take per page.
 * @returns An object containing the pagination data and methods.
 */
export const usePagination = (take?: number) => {
  const route = inject(ActivatedRoute);
  const router = inject(Router);
  const injectDefaultTake = inject(APP_PAGINATION_DEFAULT_TAKE);
  const defaultTake = take || injectDefaultTake;

  const defaultOption: PageOption = {
    skip: 0,
    take: take || defaultTake,
  };
  const data = new BehaviorSubject<PageOption>(defaultOption);
  const emit = (pageOption?: PageOption, syncWithRoute = true) => {
    const pageId = +(route.snapshot.paramMap.get('pageId') || 0);

    const currData = data.value;
    const skip = pageOption?.skip ?? currData.skip ?? 0;
    const take = pageOption?.take ?? currData.take ?? defaultTake;

    if (pageId && syncWithRoute) {
      const toPage = skip / take + 1;
      if (toPage === pageId) {
        data.next({ skip, take });
        return;
      }
      router.navigate(['../', toPage], {
        relativeTo: route,
        queryParamsHandling: 'preserve',
      });
      return;
    }

    return data.next({ skip, take });
  };
  return {
    get data() {
      return data.value;
    },
    emit,
    reset() {
      emit(defaultOption);
    },
    $: route.params.pipe(
      map((params) => {
        const pageId = +(params['pageId'] || 0);
        return Math.max((pageId - 1) * data.value.take, 0);
      }),
      map((skip) => ({ skip, take: data.value.take })),
      // emit data to subject before start watch event
      tap((newPage) => emit(newPage, false)),
      switchMap(() => data),
      shareReplay({ bufferSize: 1, refCount: true }),
    ),
  };
};
