import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const DEFAULT_DATE_VALIDATORS = {
  min: new Date(1900, 0, 1),
  max: new Date(3000, 11, 31),
};

export class DateValidators {
  static normalRange = DateValidators.range();

  static range(
    dates: typeof DEFAULT_DATE_VALIDATORS = DEFAULT_DATE_VALIDATORS,
    equal = false,
  ): ValidatorFn {
    return (
      control: AbstractControl<Date | number>,
    ): ValidationErrors | null => {
      const min = DateValidators.min(dates.min, equal)(control);
      const max = DateValidators.max(dates.max, equal)(control);

      const result = { ...min, ...max };

      return Object.keys(result).length ? result : null;
    };
  }

  static max(
    date: Date | number = DEFAULT_DATE_VALIDATORS.max.getTime(),
    equal = false,
  ): ValidatorFn {
    return (
      control: AbstractControl<Date | number>,
    ): ValidationErrors | null => {
      const value = control.value;

      if (value === null) {
        return null;
      }

      const curr = value instanceof Date ? value.getTime() : value;
      const time = date instanceof Date ? date.getTime() : date;

      return (equal ? curr >= time : curr > time)
        ? {
            maxDate: {
              maxDate: date,
              actual: value,
            },
          }
        : null;
    };
  }

  static min(
    date: Date | number = DEFAULT_DATE_VALIDATORS.min.getTime(),
    equal = false,
  ): ValidatorFn {
    return (
      control: AbstractControl<Date | number>,
    ): ValidationErrors | null => {
      const value = control.value;

      if (value === null) {
        return null;
      }

      const curr = value instanceof Date ? value.getTime() : value;
      const time = date instanceof Date ? date.getTime() : date;

      return (equal ? curr <= time : curr < time)
        ? {
            minDate: {
              minDate: date,
              actual: value,
            },
          }
        : null;
    };
  }
}
