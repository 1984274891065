import { Injectable, inject } from '@angular/core';

import { defer, firstValueFrom, shareReplay, switchMap } from 'rxjs';
import { FIREBASE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class FirebaseCenter {
  private firebase = inject(FIREBASE);
  app$ = defer(async () => {
    const app = await import('firebase/app');

    const firebaseApp = app.initializeApp(this.firebase);
    return firebaseApp;
  }).pipe(shareReplay(1));

  auth$ = this.app$.pipe(
    switchMap(async () => {
      const auth = await import('firebase/auth');

      await auth.signInAnonymously(auth.getAuth());

      return auth;
    }),
    shareReplay(1),
  );

  storage$ = this.auth$.pipe(
    switchMap(() => import('firebase/storage')),
    shareReplay(1),
  );

  storage() {
    return firstValueFrom(this.storage$);
  }

  // async getDownloadURL(url: string) {
  //   const storage = await this.storage();
  //   const ref = storage.ref(storage.getStorage(), url);

  //   return storage.getDownloadURL(ref);
  // }

  async init() {
    await this.storage();
  }
}
