import { inject, Inject, Injectable, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
  defer,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  pipe,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import type { AutoDestroy } from '@nghedgehog/core';

import { BEFORE_UNLOAD_FN, BEFORE_UNLOAD_MESSAGE } from './beforeunload.token';

interface BeforeunloadFormGroup extends InstanceType<typeof AutoDestroy> {
  formGroup: UntypedFormGroup;
  hasChange: boolean;
}

export type BeforeunloadMessageType = boolean | string | Promise<string> | null;

export const FORCE_CLOSE = Symbol('force-close');

@Injectable({
  providedIn: 'root',
})
export class BeforeunloadService {
  leaveCheckFn: { [Key: string]: () => BeforeunloadMessageType } = {};

  get leaveSubject() {
    return new Subject<any>().pipe(
      switchMap((x) => {
        const force = x?.[FORCE_CLOSE];

        if (force) {
          delete x[FORCE_CLOSE];

          // when only be force close, we should return undefined
          return of(Object.keys(x).length > 0 ? x : undefined);
        }

        return this.leaveCheck(this.message).pipe(
          filter(Boolean),
          map(() => x),
        );
      }),
      // subject always have inner next method, so we can use it
      // TODO: find a better way to do this
    ) as Subject<any>;
  }

  constructor(
    @Optional()
    @Inject(BEFORE_UNLOAD_MESSAGE)
    private message: BeforeunloadMessageType,
    @Optional()
    @Inject(BEFORE_UNLOAD_FN)
    private alertFn: (message: string) => Observable<boolean>,
  ) {}

  addLeaveCheck(fn: () => BeforeunloadMessageType) {
    const nowKey = Object.keys(this.leaveCheckFn).length;
    this.leaveCheckFn[nowKey] = fn;
    return `${nowKey}`;
  }

  leaveCheck(message?: BeforeunloadMessageType) {
    return defer(async () => {
      let toMessage = await message;
      let checkResult = false;

      for (const fn of Object.values(this.leaveCheckFn)) {
        const result = fn();
        if (typeof result === 'object') {
          toMessage = await result;
        } else if (typeof result === 'string') {
          toMessage = result;
        }

        checkResult = !!result;

        if (checkResult) {
          break;
        }
      }

      return [checkResult, toMessage];
    }).pipe(
      switchMap(([checkResult, toMessage]) => {
        if (checkResult) {
          return this.alertFn
            ? this.alertFn(toMessage as string)
            : of(confirm(toMessage as string));
        }

        return of(true);
      }),
    );
  }

  keepCurrentChangeState<T extends Observable<any> = Observable<any>>(
    instance: BeforeunloadFormGroup,
    obs$: T,
  ) {
    let keepState = false;

    return of(null).pipe(
      tap(() => {
        keepState = instance.hasChange;
        instance.hasChange = false;
      }),
      switchMap(() => obs$),
      tap(() => {
        instance.hasChange = keepState;
      }),
    );
  }

  removeLeaveCheck(key: string) {
    delete this.leaveCheckFn[key];
  }

  listenChange(instance: BeforeunloadFormGroup) {
    return of(null).pipe(this.listenChangeOperator(instance));
  }

  listenChangeOperator(
    instance: BeforeunloadFormGroup,
  ): OperatorFunction<any, any> {
    return pipe(
      tap(() => (instance.hasChange = false)),
      switchMap(() => instance.formGroup.valueChanges.pipe(take(1))),
      tap(() => (instance.hasChange = true)),
      takeUntil(instance['_destroy$']),
    );
  }
}

export const useBeforeunload = () => {
  return inject(BeforeunloadService);
};
