import { DOCUMENT } from '@angular/common';
import {
  inject,
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { firstValueFrom, forkJoin, of } from 'rxjs';

import { defaultI18nLang, websiteLanguages } from '@alan-apps/api-interfaces';
import { urlJoin } from '@alan-apps/utils';
import { TranslateService } from '@ngx-translate/core';

import { APP_TITLE } from '../constants';
import { clearI18nString } from '../pipes';

const hreflangMap: Record<string, string> = {
  en: 'en',
  zh: 'zh-hans',
  'zh-tw': 'zh-hant',
  ja: 'ja',
};

@Injectable({
  providedIn: 'root',
})
export class DocumentHeaderService {
  private getAppTitle = inject(APP_TITLE);
  private meta = inject(Meta);
  private title = inject(Title);
  private translate = inject(TranslateService);
  private rendererFactory = inject(RendererFactory2);
  private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  async setInfo(
    { title, description = '' }: { title: string; description?: string | null },
    options?: { disabledExtends?: boolean; override?: boolean },
  ) {
    const { disabledExtends = false, override = false } = options || {};
    const [i18nTitle, i18nDescription] = await firstValueFrom(
      forkJoin([
        this.translate.get(title),
        description ? this.translate.get(description) : of(''),
      ]),
    );

    const toTitle = override
      ? title
      : disabledExtends
      ? `${this.getAppTitle()}-${i18nTitle}`
      : `${this.title.getTitle()}-${i18nTitle}`;

    this.title.setTitle(toTitle);
    this.meta.updateTag({ property: 'og:title', content: toTitle });
    this.meta.updateTag({ property: 'twitter:title', content: toTitle });

    const toDescription = i18nDescription || i18nTitle;

    this.meta.updateTag({ name: 'description', content: toDescription });
    this.meta.updateTag({
      property: 'og:description',
      content: toDescription,
    });
    this.meta.updateTag({
      property: 'twitter:description',
      content: toDescription,
    });
  }

  canonical(href: string) {
    const linkElm =
      this.document.querySelector('link[rel="canonical"]') ||
      (() => {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.appendChild(this.document.head, link);

        return link;
      })();

    this.renderer.setAttribute(linkElm, 'href', href);
  }

  resetTitle() {
    this.title.setTitle(this.getAppTitle());
  }

  setImage(source: string) {
    this.meta.updateTag({ property: 'og:image', content: source });
    this.meta.updateTag({ name: 'og:image', content: source });

    this.meta.updateTag(
      { itemprop: 'image', content: source },
      'itemprop="image"',
    );
    this.meta.updateTag({ name: 'image', content: source });
  }

  updateHeaders(ogLang: string) {
    this.document.documentElement.lang = ogLang.toLowerCase().replace('_', '-');
    this.meta.updateTag({
      property: 'og:locale',
      content: ogLang,
    });
    this.meta.updateTag(
      {
        httpEquiv: 'content-language',
        content: ogLang,
      },
      'http-equiv="content-language"',
    );
  }

  upsertHrefLang(source: typeof websiteLanguages[number]) {
    const id = source.id;

    const upsertLink = (hreflang: string) => {
      const linkElm =
        this.document.querySelector(`link[hreflang="${hreflang}"]`) ||
        (() => {
          const link = this.renderer.createElement('link');
          this.renderer.setAttribute(link, 'rel', 'alternate');
          this.renderer.setAttribute(link, 'type', 'text/html');
          this.renderer.appendChild(this.document.head, link);

          return link;
        })();

      this.renderer.setAttribute(linkElm, 'hreflang', hreflang);

      const url = location.href;
      const urlWithoutOrigin = url.replace(location.origin, '');
      const href = clearI18nString(urlWithoutOrigin);

      this.renderer.setAttribute(
        linkElm,
        'href',
        urlJoin(location.origin, id, href),
      );
      this.renderer.setAttribute(linkElm, 'title', source.title);
    };

    const beDefault = id === defaultI18nLang;
    if (beDefault) {
      upsertLink('x-default');
    }

    const hreflang = hreflangMap[id] || hreflangMap['zh-tw'];
    upsertLink(hreflang);
  }
}
