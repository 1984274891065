import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent, merge, tap } from 'rxjs';

@Directive({
  selector: '[unselectable]',
  standalone: true,
})
export class UnSelectableDirective implements AfterViewInit {
  private destroy = inject(DestroyRef);
  private elm = inject(ElementRef);
  private renderer = inject(Renderer2);

  ngAfterViewInit() {
    this.renderer.setStyle(this.elm.nativeElement, 'user-select', 'none');

    merge(
      fromEvent<Event>(this.elm.nativeElement, 'contextmenu'),
      fromEvent<Event>(this.elm.nativeElement, 'selectstart'),
    )
      .pipe(
        tap((e) => e.preventDefault()),
        takeUntilDestroyed(this.destroy),
      )
      .subscribe();
  }
}
