import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { NgxRxAlertModel, NgxRxAlertService, ok } from 'ngx-rx-alert';
import { catchError, of, tap } from 'rxjs';

import { AuthorizationGQL, LoginGQL } from '@alan-apps/data-access';

import { AuthService } from './auth.service';
import { BaseHttpService } from './base-http.service';
import { PathService } from './path.service';
import { APP_URL } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AuthRequestService {
  private appURL = inject(APP_URL);
  private _auth = inject(AuthService);
  private loginGQL = inject(LoginGQL);
  private authorization = inject(AuthorizationGQL);
  private _alc = inject(NgxRxAlertService);
  private _router = inject(Router);
  private _http = inject(BaseHttpService);
  private _path = inject(PathService);

  signin(data: any) {
    return this._http.apollo(this.loginGQL.fetch(data)).pipe(
      tap((result: any) => {
        this._auth.token = result.token;
        this._path.goBack('/member');
      }),
      catchError((error: Response) =>
        this._alc.alert(
          new NgxRxAlertModel('登入失敗', '帳號或密碼錯誤', 'error'),
        ),
      ),
    );
  }

  checkUser() {
    if (this._auth.token) {
      return this._http.apollo(this.authorization.fetch()).pipe(
        // TODO: fix type
        // * that fix the bug that the rxjs issue in check-user.guard
        tap((result: any) => {
          this._auth.user = result;
        }),
      );
    }

    return of(true);
  }

  signOut(nonAsk = false) {
    const logout = async () => {
      await this._router.navigate(this.appURL.nonAuthentication);
      this._auth.user = null;
    };

    if (nonAsk) {
      logout();
      return;
    }

    this._alc
      .confirm(new NgxRxAlertModel('登出確認', '確定要登出嗎？', 'info'))
      .pipe(ok(logout))
      .subscribe();
  }
}
