import { defaultI18nLang } from '@alan-apps/api-interfaces';
import { urlJoin } from '@alan-apps/utils';

export function notBeDefaultLanguage(lang: string | null): lang is string {
  return Boolean(lang && lang !== defaultI18nLang);
}

// TODO: when support more languages, need to change this function
export function clearI18nString(url: string) {
  const cleanedUrl = url.replace(/^\/(ja|en|zh)(\/|$)/, '/');
  return cleanedUrl;
}
const transformToString: (value: any, index: number, array: any[]) => string = (
  c,
) => `${c}`;

export function getI18nRouteLink<T extends any[] | string | null | undefined>(
  commands: T,
  lang: string | null,
) {
  if (commands && notBeDefaultLanguage(lang)) {
    if (Array.isArray(commands)) {
      // if only one command and is '/', then return ['/lang']
      if (commands.length === 1 && commands[0] === '/') {
        return [urlJoin('/', lang)];
      }

      return [urlJoin('/', lang, ...commands.map(transformToString))];
    }

    if (typeof commands === 'string') {
      return [urlJoin('/', lang, commands)];
    }

    console.warn(
      'I18nLink, RouterLink only supports array or string commands',
      commands,
    );
  }

  return commands;
}

export function getI18nRouteLinkString(
  commands: string | string[],
  lang: string | null,
) {
  const toUrl = Array.isArray(commands) ? commands : [commands];
  const url = urlJoin(
    ...getI18nRouteLink<string[]>(toUrl, lang).map(transformToString),
  );
  return url;
}
