import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, Input, inject, model } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';

import { map, of } from 'rxjs';

import { isMobile, urlJoin } from '@alan-apps/utils';

import { BaseService } from '../../services';
import { APP_URL } from '../../constants';
import { input } from '@angular/core';

@Component({
  selector: 'ngx-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  standalone: true,
  imports: [MatRippleModule, MatIconModule, AsyncPipe],
})
export class SocialShareComponent {
  private appURL = inject(APP_URL);
  @HostBinding('class') classList = 'bg-gray-200/50 p-1 rounded inline-flex';

  title = input<string | undefined>();
  text = input<string | undefined>();
  url = model<string | undefined>();

  encodeURI$ = this.getEncodeURI();

  isMobile = isMobile();

  urlHeader = this.isMobile ? 'm' : 'www';

  constructor(
    private base: BaseService,
    private _title: Title,
  ) {}

  private getEncodeURI() {
    if (this.url()) {
      return of(encodeURIComponent(this.url()!));
    }
    return this.base.currentRoutePath$.pipe(
      map((url) => {
        this.url.set(urlJoin(this.appURL.base, url));
        return encodeURIComponent(this.url()!);
      }),
    );
  }

  async share(url = this.url()!) {
    try {
      await this.openShare(this.url()!);
    } catch (error) {
      window.open(url, '_blank');
    }
  }
  async shareURL() {
    try {
      await this.openShare(this.url()!);
    } catch (error) {
      this.base.copyToClipboard(this.url()!);
    }
  }

  private async openShare(url: string) {
    await navigator.share({
      title: this.title() || this._title.getTitle(),
      text: this.text() || this._title.getTitle(),
      url: url,
    });
  }
}
