import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enable',
  standalone: true,
})
export class EnablePipe implements PipeTransform {
  transform(value: boolean, addStyle = 'text-danger'): any {
    if (addStyle && !value) {
      return value
        ? '<span>啟用</span>'
        : `<span class="${addStyle}">停用</span>`;
    }

    return value ? '啟用' : '停用';
  }
}
