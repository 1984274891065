import { computed as baseComputed, computedUniqueKey } from '@alan-apps/utils';

export const computed =
  (depsCallback: (instance: any) => any[]) =>
  (target: any, key: string, descriptor: any) => {
    return baseComputed(depsCallback, (instance) =>
      // when that be component will have that context as key to know that is which component
      {
        // in standalone component that __ngContext__ will be get after a tick so make sure you extends ComputedAble in your standalone component
        return instance[computedUniqueKey] || instance['__ngContext__'];
      },
    )(target, key, descriptor);
  };
