import { Renderer2 } from '@angular/core';

export class ElementHandler {
  constructor(
    private _renderer: Renderer2,
    private _document?: Document,
  ) {}

  /**
   * add multiple style sheet once
   * @param elm target element
   * @param style style object
   */
  addStyles(elm: HTMLElement, style: { [key: string]: string | number }) {
    if (style) {
      Object.keys(style).forEach((key) => {
        const value = style[key];
        this._renderer.setStyle(elm, key, value);
      });
    }
  }

  /**
   * you can add multiple class once
   * @param elm target element
   * @param className className string, work with multiple class like `class1 class2`
   */
  addClassByString(elm: HTMLElement, className: string) {
    const currentClass = elm.getAttribute('class') || '';

    this._renderer.setAttribute(
      elm,
      'class',
      [currentClass, className].join(' ').trim(),
    );
  }

  insertAfter(newEl: HTMLElement, targetEl: HTMLElement) {
    const parentEl = targetEl.parentNode;

    if (!parentEl) return;

    if (parentEl.lastChild === targetEl) {
      this._renderer.appendChild(parentEl, newEl);
    } else {
      this._renderer.insertBefore(parentEl, newEl, targetEl.nextSibling);
    }
  }

  getElmOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  removeElement(elm: HTMLElement) {
    this._renderer.removeChild(elm.parentNode, elm);
  }

  elementsFromPoint(
    x: number,
    y: number,
    checkCb: (elm: HTMLElement) => boolean = () => true,
  ) {
    return new Promise<HTMLElement | null>((resolve) => {
      const checkedElm = [],
        elmsStyle = [];

      let elm: HTMLElement, i: number, d: { value: any; priority: any };
      let getElm: HTMLElement | null = null;

      // get all elements via elementFromPoint, and remove them from hit-testing in order
      while (
        (elm = this._document?.elementFromPoint(x, y) as HTMLElement) &&
        checkedElm.indexOf(elm) === -1 &&
        elm != null
      ) {
        // push the element and its current style
        checkedElm.push(elm);
        if (checkCb(elm)) {
          getElm = elm;
          break;
        }

        elmsStyle.push({
          value: elm.style.getPropertyValue('pointer-events'),
          priority: elm.style.getPropertyPriority('pointer-events'),
        });
        // set pointer-event be none, make that can find element to next layer
        elm.style.setProperty('pointer-events', 'none', 'important');
      }

      // restore the previous pointer-events values
      for (i = elmsStyle.length; (d = elmsStyle[--i]); ) {
        checkedElm[i].style.setProperty(
          'pointer-events',
          d.value ? d.value : '',
          d.priority,
        );
      }

      if (getElm) {
        resolve(getElm);
      } else {
        resolve(null);
      }
    });
  }
}
