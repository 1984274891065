import { ViewportScroller } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router, Scroll } from '@angular/router';

import { filter, pairwise, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InMemoryScrollService {
  constructor() {
    const router = inject(Router);
    const viewportScroller = inject(ViewportScroller);

    router.events
      .pipe(
        filter((event): event is Scroll => event instanceof Scroll),
        pairwise(),
        filter(([prev, next]) => prev.routerEvent.url !== next.routerEvent.url),
        tap(([, next]) => {
          if (next.position) {
            viewportScroller.scrollToPosition(next.position);
            return;
          }

          viewportScroller.scrollToPosition([0, 0]);
        }),
      )
      .subscribe();
  }
}
