<nav class="flex justify-center items-center flex-wrap gap-4">
  @if (totalCount() > 0) {
    <ul class="flex">
      <li [class.invisible]="!info.hasPreviousPage">
        <a
          [routerLink]="
            redirectMode() ? [redirectUrl, info.currentPageIndex] : undefined
          "
          (click)="handleClick($event, info.currentPageIndex!)"
          [queryParams]="queryParams()"
        >
          <i class="material-icons text-base">keyboard_arrow_left</i>
        </a>
      </li>
      <li>
        <a
          [routerLink]="redirectMode() ? [redirectUrl, 1] : undefined"
          (click)="handleClick($event, 1)"
          [queryParams]="queryParams()"
          [ngClass]="{ 'bg-primary text-white': info.currentPageIndex === 0 }"
        >
          1
        </a>
      </li>
      @if (currentCursor > 3) {
        <li>
          <a (click)="resetCursor(currentCursor - 2)"> ... </a>
        </li>
      }
      @for (paginator of currentPages; track paginator) {
        <li>
          <a
            [routerLink]="redirectMode() ? [redirectUrl, paginator] : undefined"
            (click)="handleClick($event, paginator)"
            [queryParams]="queryParams()"
            [ngClass]="{
              'bg-primary text-white': info.currentPageIndex === paginator - 1
            }"
          >
            {{ paginator }}
          </a>
        </li>
      }
      @if (currentCursor < info.totalPageCount! - 2) {
        <li>
          <a (click)="resetCursor(currentCursor + 2)"> ... </a>
        </li>
      }
      @if (info.totalPageCount !== 1) {
        <li>
          <a
            [routerLink]="
              redirectMode() ? [redirectUrl, info.totalPageCount] : undefined
            "
            (click)="handleClick($event, info.totalPageCount!)"
            [queryParams]="queryParams()"
            [ngClass]="{
              'bg-primary text-white':
                info.currentPageIndex === info.totalPageCount! - 1
            }"
          >
            {{ info.totalPageCount }}
          </a>
        </li>
      }
      <li [class.invisible]="!info.hasNextPage">
        <a
          [routerLink]="
            redirectMode()
              ? [redirectUrl, info.currentPageIndex! + 2]
              : undefined
          "
          (click)="handleClick($event, info.currentPageIndex! + 2)"
          [queryParams]="queryParams()"
        >
          <i class="material-icons text-base">keyboard_arrow_right</i>
        </a>
      </li>
    </ul>
  }
  <div class="flex">
    <span
      >{{ '每頁' | translate }}
      {{ take() }}
      {{ '筆' | translate }}&nbsp;</span
    >
    <i class="flex-auto"></i>
    <span>{{ '共' | translate }}{{ totalCount() }}{{ '筆' | translate }}</span>
  </div>
</nav>
