import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ngx-loading-mask',
  templateUrl: './loading-mask.component.html',
  styleUrls: ['./loading-mask.component.scss'],
  standalone: true,
})
export class LoadingMaskComponent {
  @HostBinding('class') className = 'contents';
}
