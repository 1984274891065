import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taiwanDate',
  standalone: true,
})
export class TaiwanDatePipe implements PipeTransform {
  transform(date: string | Date | null | undefined): string {
    if (!date) return '';
    let time = '';

    if (typeof date === 'string') {
      const [dateString, timeString] = date.split(' ');
      date = new Date(dateString);

      time = ` ${timeString}`;
    }

    const d =
      typeof date === 'string' || typeof date === 'number'
        ? new Date(date)
        : date;
    const year = d.getFullYear() - 1911;

    return `民國${year}年${d.getMonth() + 1}月${d.getDate()}日${time}`;
  }
}
