import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

import {
  BeforeunloadMessageType,
  BeforeunloadService,
} from './beforeunload.service';

@Directive({
  selector: '[beforeunload]',
  standalone: true,
})
export class BeforeunloadDirective implements AfterViewInit, OnDestroy {
  @Input('beforeunload') event!: () => BeforeunloadMessageType;

  private eventId!: string;

  constructor(private beforeunload: BeforeunloadService) {}

  ngAfterViewInit(): void {
    this.eventId = this.beforeunload.addLeaveCheck(this.event);
  }

  @HostListener('window:beforeunload', ['$event'])
  public doSomething($event: Event) {
    if (this.event()) {
      $event.returnValue = true;
      return;
    }
  }

  ngOnDestroy(): void {
    this.beforeunload.removeLeaveCheck(this.eventId);
  }
}
