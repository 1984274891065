import { DomPortalOutlet } from '@angular/cdk/portal';
import { ComponentRef, InjectionToken, TemplateRef, Type } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import type { NgxRxModalComponent } from './ngx-rx-modal.component';

export type ModalComponentType = Type<any> | TemplateRef<any>;

export interface NgxRxModalInjectModel {
  portalHost: DomPortalOutlet;
  component: ModalComponentType;
  option: NgxRxModalOption;
  id: string;
  get atTop(): boolean;
  get componentRef(): ComponentRef<NgxRxModalComponent>;
}

export const NGX_RX_MODAL_TOKEN = new InjectionToken<NgxRxModalInjectModel>(
  'NGX_RX_MODAL_TOKEN',
);

export const NGX_RX_MODAL_MODE = new InjectionToken<boolean>(
  'NGX_RX_MODAL_MODE',
);

export const NGX_RX_MODAL_ELEMENT = new InjectionToken<HTMLDivElement>(
  'NGX_RX_MODAL_ELEMENT',
);

export const NGX_RX_MODAL_CLOSE = new InjectionToken('NGX_RX_MODAL_CLOSE');

/**
 * addition exist check when keydown esc
 */
export const NGX_RX_MODAL_ADDITION_CHECK_ON_ESC = new InjectionToken(
  'NGX_RX_MODAL_ADDITION_CHECK_ON_ESC',
);

export type NgxRxModalClasses = {
  /** class apply on root */
  root?: string;
  /** class apply on panel */
  panel?: string;
  /** class apply on backdrop */
  backdrop?: string;
  /** class apply on close wrapper */
  close?: string;
};

export const NGX_RX_MODAL_CLASSES = new InjectionToken<NgxRxModalClasses>(
  'NGX_RX_MODAL_CLASSES',
);

export interface NgxRxModalRef {
  complete: Subject<any>;
  /**
   * value be emit when the modal is closed
   */
  closeEmitValue?: any;
  /**
   * opacity done
   */
  showHidden$?: Subject<any>;
}

export interface NgxRxModalOption {
  /** This title will add on browser title */
  title?: string;
  /** Data being injected into the child component. */
  data?: { [key: string]: any };
  /** Data being injected into the child component. */
  resolve?: { [key: string]: Observable<any> };
  /** Custom class for the overlay pane. */
  panelClass?: string;
  /** Custom class for the backdrop, */
  backdropClass?: string;
  /** style of the dialog. */
  panelStyle?: any;
  /** style of the backdrop. */
  backdropStyle?: any;
  /** Whether the user can use escape or clicking outside to close a modal. */
  disableClose?: boolean;
  /** disable close button render */
  disableCloseButton?: boolean;
  /** disabled backdrop click to close modal */
  disableBackdropClick?: boolean;
  /* main window animate */
  windowAnimate?: 'zoomIn' | 'bounceInDown' | 'fadeIn';
  /** when lt-md fix to 100%  */
  notMdFix?: boolean;
  /** elevation height */
  elevation?: number;
  /** is check layout in the container */
  fixedContainer?: boolean;
  /** is add url with modal */
  redirectURL?: string;
  /** is add url with modal */
  noRedirect?: boolean;
  /** method to do when window be close by close button or backdrop */
  onClose?: () => any | Promise<any>;
  /**
   * hidden open modal, that can be use with showHidden when your data ready,
   */
  hiddenOpen?: boolean;
}
