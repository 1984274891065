// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import { map, MonoTypeOperatorFunction, OperatorFunction, tap } from 'rxjs';

export const justData = <T, R>(): OperatorFunction<T, R> =>
  map((data) => {
    return data[Object.keys(data)[0]];
  });

export const handelNodes = <T>(
  cb: (nodes: T) => void,
): MonoTypeOperatorFunction<any> =>
  tap((data) => {
    cb(data['nodes'] as T);
  });

export const handelPageInfo = (
  cb: (pageInfo: PaginatorInfo) => void,
): MonoTypeOperatorFunction<any> =>
  tap((data) => {
    cb(data['pageInfo'] as PaginatorInfo);
  });

export const toNodes = <T extends { nodes?: unknown }>(): OperatorFunction<
  T,
  T['nodes']
> =>
  map((data) => {
    return data?.['nodes'] as T['nodes'];
  });

// export function toNodes<T>(): MonoTypeOperatorFunction<T['nodes']> {
//   return map((data) => data?.['nodes']);
export const toPageInfo = <T, R>(): OperatorFunction<T, R> =>
  map((data) => {
    return data['pageInfo'];
  });
