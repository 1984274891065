// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { format } from 'date-fns';

// TODO: find an better name
export function clearObjToForm(
  obj: any,
  isDate = true,
  valueHandler?: (value: unknown) => any,
  hasNull = true,
) {
  if (!obj) {
    return obj;
  }
  const outObj = {};
  Object.keys(obj).forEach((k) => {
    const value = obj[k];
    if (value !== null && value !== undefined) {
      if (value instanceof Date && isDate) {
        outObj[k] = format(value, 'yyyy-MM-dd');
      } else {
        const toValue = valueHandler ? valueHandler(value) : value;

        if (toValue !== null) {
          outObj[k] = toValue;
        } else {
          if (hasNull) {
            outObj[k] = null;
          }
        }
      }
    }
  });
  return outObj;
}
