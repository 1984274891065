import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';

import { map } from 'rxjs';

import { AuthRequestService } from '../services/auth-request.service';

export const userValidateGuard: CanActivateFn | CanActivateChildFn = (
  route,
) => {
  const auth = inject(AuthRequestService);
  const userGuardCheck = route.data['userGuardCheck'];
  const checkUser$ = auth.checkUser();

  if (userGuardCheck) {
    return checkUser$.pipe(map(() => true));
  }

  // non block page init
  checkUser$.subscribe();

  return true;
};
