import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';

import { urlJoin } from '@alan-apps/utils';
import { TranslateModule } from '@ngx-translate/core';

import { MenuModel } from '@alan-apps/data-access';
import { I18nLinkPipe } from '../../pipes/i18n-link/i18n-link.pipe';
import { input } from '@angular/core';

@Component({
  selector: 'ngx-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, I18nLinkPipe, RouterLink, TranslateModule],
})
export class BreadCrumbComponent {
  @Input()
  get links(): MenuModel[] {
    return this._links;
  }
  set links(value: MenuModel[]) {
    this._links = value || [];
    this.checkLinks();
  }
  noHome = input(false);

  private _links: MenuModel[] = [];

  private checkLinks() {
    let saveUrl = '/';
    let saveQueryParams = {};

    this._links = this.links.map(
      ({ url, title, fullUrl, queryParams, ...rest }) => {
        if (!fullUrl) {
          saveUrl = urlJoin(saveUrl, url as string);
          saveQueryParams = {
            ...saveQueryParams,
            ...queryParams,
          };
        }

        return {
          url: fullUrl ? url : saveUrl,
          title,
          queryParams: saveQueryParams,
          ...rest,
        };
      },
    );
  }
}
