export class StringHandler {
  constructor(private content: string) {
    this.content = content;
  }

  brToSpace() {
    this.content = this.content.replace(/<br>/g, ' ');
    return this;
  }

  replaceToBr() {
    this.content = this.content.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>');
    return this;
  }

  getLine() {
    return this.content.split(/\r*\n/);
  }

  getByte() {
    // eslint-disable-next-line no-control-regex
    const twoCount = this.content.match(/[^\x00-\xff]/gi);
    return this.content.length + (!twoCount ? 0 : twoCount.length);
  }

  hrefToAnchor(className?: string) {
    const exp =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    this.content = this.content.replace(
      exp,
      `<a target="_blank" class="${className || ''}" href="$1">$1</a>`,
    );
    // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
    return this;
  }

  isHttpUrl() {
    const exp =
      /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

    return this.content.match(exp);
  }

  limit(num: number) {
    if (this.content.length > num) {
      this.content = `${this.content.substring(0, num)}...`;
    }
    return this;
  }

  toString() {
    return this.content;
  }

  isEmpty() {
    return !this.content || !this.content.trim();
  }

  isNumeric() {
    return !isNaN(parseFloat(this.content)) && isFinite(<any>this.content);
  }

  trim() {
    this.content.replace(/\r?\n|\r|\s/g, '');
  }

  getPath() {
    const arr = this.content.match(/(?:\w+)?\/[^/]+([^?#]+)/);
    return arr ? arr.shift() || '' : '';
  }

  getHash() {
    const arr = this.content.match(/(#[^]+)/);
    return arr ? arr.shift() : '';
  }

  /**
   * get attribute from html tag
   */
  getHTMLAttributes(): { [key: string]: string } | null {
    const regex = /(\w+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g;

    const matches = this.content.match(regex);

    if (matches && matches.length > 0) {
      return matches.reduce((acc, curr) => {
        const splitFlag = '=';
        const [key, ...value] = curr.split(splitFlag);
        acc[key] = value.join(splitFlag).replace(/['"]/g, '');

        return acc;
      }, {} as any);
    }

    return null;
  }
}
