import { Pipe, PipeTransform } from '@angular/core';

import { SOCIAL_TYPE } from '@alan-apps/api-interfaces';
import { UserSocial } from '@alan-apps/data-access';

@Pipe({
  name: 'social',
  standalone: true,
})
export class SocialPipe implements PipeTransform {
  transform(value: UserSocial, args?: any): any {
    switch (value.type) {
      case SOCIAL_TYPE.fb:
        return 'icon-facebook text-[#3b5998]';
      case SOCIAL_TYPE.ig:
        return 'icon-instagram text-secondary';
      case SOCIAL_TYPE.line:
      default:
        return 'icon-line text-[#00c300]';
    }
  }
}
