/* eslint-disable @angular-eslint/no-input-rename */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { allAnimation } from 'ngx-rx-modal';
import { EMPTY, merge, of, switchMap, tap, timer } from 'rxjs';

const betweenTime = 3000;
const delayTime = 600;
const delay = `${delayTime}ms cubic-bezier(0.4, 0.0, 0.6, 1)`;

@Component({
  selector: 'ngx-animate-text',
  standalone: true,
  imports: [CommonModule],
  template: `
    <h3 [@animate]="{ value: animate(), params: { delay: delay } }">
      {{ textItem$ | async }}
    </h3>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./typing-animate-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [allAnimation],
})
export class TypingAnimateTextComponent {
  delay = delay;

  textList = input<string[]>([], {});

  animate = signal('fadeIn');

  textItem$ = toObservable(this.textList).pipe(
    switchMap((textList) =>
      timer(0, betweenTime).pipe(
        switchMap((val) => {
          this.animate.set('fadeIn');
          const item = textList[val % textList.length];

          return merge(
            of(item),
            timer(betweenTime - delayTime).pipe(
              tap(() => {
                this.animate.set('fadeOut');
              }),
              switchMap(() => EMPTY),
            ),
          );
        }),
      ),
    ),
  );

  toggleAnimate() {
    const animate = this.animate();
    this.animate.set(animate === 'fadeIn' ? 'fadeOut' : 'fadeIn');
  }
}
