import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { cancel, ok } from 'ngx-rx-alert';

import { GetPageGQL } from '@alan-apps/data-access';

import { cache, ICache } from '../decorators';
import {
  BaseHttpService,
  DocumentHeaderService,
  PathService,
} from '../services';
import { PathResolve } from './path.resolve';

@Injectable({
  providedIn: 'root',
})
export class PageResolve implements ICache {
  readonly storageKey = 'PageResolve';

  private path = inject(PathService);
  constructor(
    private _http: BaseHttpService,
    private _title: DocumentHeaderService,
    private pathResolve: PathResolve,
    private getPageGQL: GetPageGQL,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const path = this.pathResolve.resolve(route);
    const pageId = path || route.data['path'] || route.url[0].path;
    const withBody = !!route.data['withBody'];

    return this.getPage(pageId, withBody).pipe(
      ok((page) => {
        const title = page['title'];
        const description = page['description'];
        if (title) {
          this._title.setInfo(
            {
              title,
              description,
            },
            { disabledExtends: true },
          );
        }
        // TODO: currently not have meta tags
        // if (page.metas) {
        //   page.metas.forEach((m) => {
        //     this._meta.updateTag({ name: m.name, content: m.content });
        //   });
        // }
      }),
      cancel(() => {
        this._title.resetTitle();
        this.path.goNotFound();
      }),
    );
  }

  @cache()
  private getPage(pageId: any, withBody: boolean) {
    return this._http.apollo(
      this.getPageGQL.fetch({
        id: pageId,
        withBody,
      }),
    );
  }
}
