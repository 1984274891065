export enum EXPENSE_STATUS {
  waitConfirm,
  confirm,
  waitPay,
  completed,
}

export const EXPENSE_STATUS_LIST = [
  EXPENSE_STATUS.waitConfirm,
  EXPENSE_STATUS.confirm,
  EXPENSE_STATUS.waitPay,
  EXPENSE_STATUS.completed,
];
