import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';

import { NgxRxAlertService } from 'ngx-rx-alert';
import { FileError } from 'ngxf-uploader';

import { FileDetail, InfoPhoto } from '@alan-apps/data-access';

export type CheckInfoPhoto = InfoPhoto & {
  checked: boolean;
  sort?: number;
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private _alc: NgxRxAlertService) {}

  fileErrorHandler(error: FileError) {
    switch (error) {
      case FileError.NumError:
        return this._alc.alert('檔案數量錯誤');
      case FileError.SizeError:
        return this._alc.alert('檔案大小錯誤，不可大於500KB');
      case FileError.TypeError:
        return this._alc.alert('檔案格式錯誤');
    }
  }

  moveArrayAndGetFromTo(
    previousIndex: number,
    currentIndex: number,
    array: any[],
  ) {
    const isBigger = previousIndex < currentIndex;
    const from = isBigger ? previousIndex : currentIndex;
    const to = isBigger ? currentIndex : previousIndex;
    moveItemInArray(array, previousIndex, currentIndex);
    return { from, to };
  }

  getCheckedNeedUploadFiles(photos: CheckInfoPhoto[], withPath = false) {
    return photos
      .filter((x) => x.checked && x.file)
      .map((x) => {
        const fileDetail = x.file!;
        const file: FileDetail = {
          id: fileDetail.id,
          targetPath: fileDetail.targetPath,
          contentType: fileDetail.contentType,
          originalname: fileDetail.originalname,
          watermark: fileDetail.watermark,
        };

        // only when have uploaded items need attach path object, that means that is old object
        if (withPath && fileDetail.path?.hq) {
          file.path = fileDetail.path;
        }

        return {
          ...x,
          file,
        };
      });
  }
}
