import { indexBy, prop } from 'ramda';

export enum PROPERTY_STATE {
  /** 未成交 */
  None = 0,
  /** 下架 */
  Discontinued = 1,
  Pause = 2,
  /** 斡旋 */
  Mediating = 3,
  /** 談定 */
  Negotiated = 4,
  /** 收定 */
  Ordered = 5,
  /** 降價 */
  priceOff = 6,
}

export const propertyStates = [
  { id: PROPERTY_STATE.None, title: '未成交' },
  { id: PROPERTY_STATE.priceOff, title: '降價' },
  { id: PROPERTY_STATE.Mediating, title: '斡旋' },
  { id: PROPERTY_STATE.Negotiated, title: '談定' },
  { id: PROPERTY_STATE.Ordered, title: '收定', hint: '(前台不顯示)' },
  { id: PROPERTY_STATE.Pause, title: '暫停帶看', hint: '(前台不顯示)' },
  { id: PROPERTY_STATE.Discontinued, title: '下架', hint: '(前台不顯示)' },
];

export const propertyStatesMap = indexBy(prop('id'), propertyStates);

export const propertyTypes = [
  {
    id: '0',
    title: '租屋',
  },
  {
    id: '1',
    title: '買屋',
  },
  // {
  //   id: '4',
  //   title: '店面辦公',
  // },
  // {
  //   id: '3',
  //   title: '土地廠房',
  // },
  {
    id: '2',
    title: '新屋推薦',
  },
  {
    id: 'overseas',
    url: '/company/page/1?category=overseas-real-estate',
    title: '海外不動產',
  },
  {
    id: 'new-house',
    url: '/blog/page/1?category=new%20house',
    title: '新建案推薦',
  },
];

export enum PROPERTY_PATTERN {
  Room = 0,
  Hall = 1,
  Bathroom = 2,
  Balcony = 3,
  Kitchen = 4,
  OpenSpace = 5,
}

export const propertyPatterns = [
  PROPERTY_PATTERN.Room,
  PROPERTY_PATTERN.Hall,
  PROPERTY_PATTERN.Bathroom,
  PROPERTY_PATTERN.Balcony,
  PROPERTY_PATTERN.Kitchen,
  PROPERTY_PATTERN.OpenSpace,
];

export enum PARKING_TYPE {
  Plane = 0,
  Mechanical = 1,
}

export enum RENT_ATTACHMENT_USAGE {
  Home = 0,
  Business = 1,
  HomeBusiness = 2,
  Office = 3,
  Factory = 4,
  Land = 5,
}

export enum RENT_ATTACHMENT_TYPE {
  Manage = 0,
  Parking = 1,
  Network = 2,
  TV = 3,
  MOD = 4,
  OTHER = 5,
}

export enum PROPERTY_TYPE {
  RENT = 0,
  SELL = 1,
}

export const property_types = [PROPERTY_TYPE.RENT, PROPERTY_TYPE.SELL];

export const RENT_DEVICE = {
  洗衣機: 1,
  冰箱: 2,
  電視: 3,
  冷氣: 4,
  熱水器: 5,
  網路: 6,
  第四台: 7,
  天然瓦斯: 8,
  // below is still not add into database
  流理臺: 9,
  瓦斯爐: 10,
  窗簾: 11,
  浴缸: 12,
  免治馬桶: 13,
  烘衣機: 14,
  微波爐: 15,
  烤箱: 16,
  烘碗機: 17,
  洗碗機: 18,
  電磁爐: 19,
  吸塵器: 20,
  濾水設備: 21,
} as const;

export const RENT_FURNITURES = {
  床架: 1,
  衣櫃: 2,
  沙發: 3,
  桌子: 4,
  椅子: 5,
  // below is still not add into database
  茶几: 6,
  餐桌椅: 7,
  置物櫃: 8,
  床墊: 9,
  書桌椅: 10,
} as const;

// 養寵物: 26,
// 短租: 27,

export const PUBLIC_UTILITIES = {
  游泳池: 0,
  健身房: 1,
  交誼廳: 2,
  會議室: 3,
  環保室: 4,
  視聽室: 5,
  宴會廳: 6,
  中庭花園: 7,
  空中花園: 8,
  蒸氣浴: 9,
  遊戲室: 10,
  桌球間: 11,
  '圖書室/閱覽室': 12,
  家教室: 13,
  廚藝教室: 14,
  KTV: 15,
  LoungeBar: 16,
  接待大廳: 17,
  信箱區: 18,
  曬被區: 19,
  洗衣間: 20,
  兒童遊戲室: 21,
  撞球室: 22,
  棋藝室: 23,
  籃球場: 24,
} as const;

export const LIFE_FUNCTIONS = {
  便利商店: 0,
  傳統市場: 1,
  大型醫院: 2,
  百貨公司: 3,
  公園綠地: 4,
  學校: 5,
  夜市: 6,
  // * schools
  美國學校: 7,
  日僑學校: 8,
  大學: 9,
  高中: 10,
  國中: 11,
  國小: 12,
  幼兒園: 13,
  其他: 14,
} as const;

export const RENT_COVER_ATTACHMENTS = {
  管理費: 1,
  車位租金: 2,
  網路: 3,
  第四台: 4,
  MOD: 5,
  其他: 6,
  水費: 7,
  電費: 8,
  二代健保: 9,
  含稅: 10,
} as const;

// export enum PROPERTY_KEY_BELONG_TYPE {
//   /** 店長 */
//   StoreManager = 0,
//   /** 公司 */
//   Company = 1,
//   /** 專員 */
//   Agent = 2,
//   /** 密碼 */
//   Password = 3,
//   /** 密碼 */
//   Local = 4,
//   /** 密碼 */
//   HouseOwner = 5,
// }

export const propertyKeyBelongTypes = [
  '店長',
  '公司',
  '專員',
  '密碼',
  '現場',
  '屋主',
];

export enum PROPERTY_MAIN_STRUCTURE_TYPE {
  /** 鋼筋混擬土 */
  ReinforcedConcrete = 0,
  /** 鋼骨結構 */
  SteelStructure = 1,
  /** 加強磚造 */
  ReinforcedBrickwork = 2,
  /** 其他 */
  Other = 3,
}

export const propertyMainStructures = [
  '鋼筋混擬土',
  '鋼骨結構',
  '加強磚造',
  '其他',
];

export enum PROPERTY_SECURITY_GUARD_TYPE {
  /** 無保全 */
  No = 0,
  /** 非24小時保全 */
  Yes = 2,
  /** 有24小時保全 */
  Yes24 = 1,
}

export const propertySecurityGuards = [
  '無保全',
  '非24小時保全',
  '有24小時保全',
];

export enum PROPERTY_MOTORCYCLE_STATE {
  /** 固定機車位 */
  Fixed = 0,
  /** 年抽籤承租 */
  Annual = 1,
}

export const propertyMotorcycleStates = ['固定機車位', '年抽籤承租'];

export const exportPropertyStates = ['架上', '下架', '二次上架'];

export enum PROPERTY_CONTRACT_TYPE {
  /** 架上 */
  Normal = 0,
  /** 下架 */
  Special = 1,
}

export const propertyContractTypes = ['一般', '專約'];

export const propertyInternalStates = [
  '非空屋',
  '空屋',
  '空屋可補家具電',
  '附家具電',
  '空屋附基本裝修',
  '家具有缺可補',
  '毛胚屋',
];

// 附家具電
// 家具有缺可補
// 空屋
// 空屋附基本裝修
// 空屋可補家具電
// 毛胚屋

export enum PROPERTY_PARKING_Category {
  /** 平面 */
  Plane = 0,
  /** 機械 */
  Mechanical = 1,
}

export const propertyParkingCategories = ['平面', '機械'];

export const propertyParkingGarageTypes = [
  '無',
  '平面',
  '機械上層',
  '機械中層',
  '機械下層',
  '機械平移',
  '車位塔',
  '另可租',
];

export const propertyParkingLotTypes = ['無', '坡道', '機械升降', '1樓'];

export type PropertyReviewStatus =
  | 'temp'
  | 'pending'
  | 'reviewing'
  | 'canceled'
  | 'approved'
  | 'rejected';

export const propertyReviewStatusList = [
  // 'temp',
  'pending',
  // 'reviewing',
  'canceled',
  'approved',
  'rejected',
];

export type PropertyReviewData = {
  updateValue: any;
  /**
   * original form group data
   */
  formData: any;
  /**
   * that data to save into database
   */
  saveData: any;
};
