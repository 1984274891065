import {
  MonoTypeOperatorFunction,
  ObservableInput,
  ObservedValueOf,
  of,
  OperatorFunction,
  switchMap,
  tap,
} from 'rxjs';

export const ok = <T>(
  next: (value: NonNullable<T>) => void,
): MonoTypeOperatorFunction<T> =>
  tap((value) => {
    if (value || (value as any) === '') {
      next(value!);
    }
  });

export const okTo = <T, O extends ObservableInput<any>>(
  project: (value: NonNullable<T>, index: number) => O,
): OperatorFunction<T, ObservedValueOf<O>> =>
  switchMap((data, index) =>
    data || data === '' ? project(data as any, index) : of(data),
  ) as any;
