import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  inject,
  Input,
  model,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';

import { Property } from '@alan-apps/data-access';
import { TranslateModule } from '@ngx-translate/core';

import { CURRENT_LANG_GETTER, GEOCODING_KEY } from '../../constants';
import { SafePipe } from '../../pipes';
import { MapService } from './map.service';
import { input } from '@angular/core';

@Component({
  selector: 'ngx-google-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: true,
  imports: [
    GoogleMapsModule,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
    SafePipe,
  ],
})
export class MapComponent {
  getCurrentLocale = inject(CURRENT_LANG_GETTER, { optional: true });
  private key = inject(GEOCODING_KEY);
  @ContentChild('infoWindow') infoTemplateRef!: TemplateRef<any>;
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  @ViewChild('marker')
  public get defaultMarker(): MapMarker {
    return this._defaultMarker;
  }
  public set defaultMarker(value: MapMarker) {
    this._defaultMarker = value;
    this.openInfoWindow(value, -1);
  }
  private _defaultMarker!: MapMarker;

  private _nears: Property[] = [];

  @Input()
  public get centerPosition() {
    return this._centerPosition;
  }
  public set centerPosition(value: google.maps.LatLngLiteral) {
    this._centerPosition = value;

    if (!this.interactive()) {
      this.staticUrl = this.getStaticUrl(value);
    }
  }
  private _centerPosition!: google.maps.LatLngLiteral;

  nearsPositions: google.maps.LatLngLiteral[] = [];

  staticUrl?: string;
  infoProperty?: Property;
  lang = this.getCurrentLocale?.();
  apiLoaded$ = this._map.getLoadMapScript(this.lang);

  options: google.maps.MapOptions = {
    zoom: 16,
  };

  disableController = input(false);
  interactive = model(false);

  @Input()
  public get center(): Property {
    return this._center;
  }
  public set center(value: Property) {
    this._center = value;

    const geopoint = value.geopoint;
    if (geopoint && geopoint.latitude && geopoint.longitude) {
      this.centerPosition = {
        lat: geopoint.latitude,
        lng: geopoint.longitude,
      };

      this.options.center = this.centerPosition;
    }
  }
  private _center!: Property;

  @Input()
  public get nears(): Property[] {
    return this._nears;
  }
  public set nears(value: Property[]) {
    this._nears = value;

    this.nearsPositions = this.nears.map((near) => {
      return {
        lat: near.geopoint!.latitude!,
        lng: near.geopoint!.longitude!,
      };
    });
  }

  // openLink = 'https://www.google.com/maps';
  constructor(
    private _cd: ChangeDetectorRef,
    private _map: MapService,
  ) {}

  openInfoWindow(marker: MapMarker, index = 0) {
    if (!this.infoWindow) return;

    const isNear = index > -1;

    this.infoProperty = isNear ? this.nears[index] : this.center;
    this._cd.detectChanges();
    this.infoWindow.open(marker);
  }

  private getStaticUrl(geopoint: google.maps.LatLngLiteral) {
    const location = `${geopoint.lat},${geopoint.lng}`;

    // signature 之後要補上，方法要在查詢
    // https://developers.google.com/maps/documentation/maps-static/get-api-key
    const queryString = new HttpParams({
      fromObject: {
        center: location,
        zoom: '16',
        key: this.key,
        size: '400x400',
        markers: `color:red|label:A|${location}`,
        // language: this.lang,
      },
    }).toString();
    return `https://maps.googleapis.com/maps/api/staticmap?` + queryString;
  }
}
