import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { useBeforeunload } from './beforeunload.service';
import { BEFORE_UNLOAD_MESSAGE } from './beforeunload.token';

export const leaveGuard: CanDeactivateFn<any> = () => {
  const service = useBeforeunload();
  const message = inject(BEFORE_UNLOAD_MESSAGE, { optional: true });
  return service.leaveCheck(message);
};
