export enum LOCATION_CATEGORY {
  massTransit = 'massTransit',
  others = 'others',
}

export const locationCategoryTypes = [
  {
    id: LOCATION_CATEGORY.others,
    name: '其他',
  },
  {
    id: LOCATION_CATEGORY.massTransit,
    name: '大眾運輸',
  },
];

export const COMPANY_CATEGORY_ID = 'company';
