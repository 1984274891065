import { Inject, Injectable, Optional } from '@angular/core';

import { NgxRxAlertService, okTo } from 'ngx-rx-alert';
import { catchError, map, Observable, of, shareReplay, switchMap } from 'rxjs';

import { BlockViewService } from '../../components';
import { CURRENT_LANG_GETTER } from '../../constants';
import { InfoService } from '../../services/info.service';
import { MapService } from './map.service';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  constructor(
    private _alc: NgxRxAlertService,
    private _info: InfoService,
    private _block: BlockViewService,
    private _map: MapService,
    @Optional()
    @Inject(CURRENT_LANG_GETTER)
    private getCurrentLocale: () => string,
  ) {}

  geocoder$ = of(null).pipe(
    switchMap(() => {
      const local = this.getCurrentLocale();
      return this._map.getLoadMapScript(local);
    }),
    map(() => new google.maps.Geocoder()),
    shareReplay(1),
  );

  geocodeAddress(location: string): Observable<any> {
    if (!location) {
      return this._alc.alert('請輸入縣市、區域、地址');
    }

    return this._block.next(
      this.geocoder$.pipe(
        switchMap((geocoder) => {
          return new Observable((observer) => {
            console.log(location);
            geocoder.geocode({ address: location }, (results, status) => {
              if (results && status === google.maps.GeocoderStatus.OK) {
                // console.log('Geocoding complete!');
                observer.next({
                  latitude: results[0].geometry.location.lat(),
                  longitude: results[0].geometry.location.lng(),
                });
              } else {
                observer.error({ latitude: 0, longitude: 0 });
              }
              observer.complete();
            });
          }).pipe(
            okTo((value) => {
              // console.log(value);
              return this._info.show('取得位置成功').pipe(map(() => value));
            }),
            catchError((err) => this._info.show('找不到這個目的地')),
          );
        }),
      ),
    );
  }
}
