import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  leaveCheckFn: Record<string, () => boolean> = {};

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  addLeaveCheck(fn: () => boolean) {
    const nowKey = `${Object.keys(this.leaveCheckFn).length}`;
    this.leaveCheckFn[nowKey] = fn;

    return nowKey;
  }

  removeLeaveCheck(key: string) {
    delete this.leaveCheckFn[key];
  }
}
