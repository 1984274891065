import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { urlJoin } from '@alan-apps/utils';

@Injectable({
  providedIn: 'root',
})
export class PathResolve {
  resolve(route: ActivatedRouteSnapshot) {
    const rootPath = route.data['rootPath'];

    if (rootPath) {
      const id = route.url[0].path;
      return urlJoin(rootPath, id);
    }

    return null;
  }
}
