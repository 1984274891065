import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Params, RouterLink } from '@angular/router';

import { urlJoin } from '@alan-apps/utils';
import { TranslateModule } from '@ngx-translate/core';

import { BaseService } from '../../services';
import { APP_PAGINATION_DEFAULT_TAKE } from '../../constants';
import { input } from '@angular/core';

interface PaginatorInfo {
  currentPageIndex?: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  totalPageCount?: number;
}

@Component({
  selector: 'ngx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, TranslateModule],
})
export class MyPaginatorComponent implements OnInit {
  defaultTake = inject(APP_PAGINATION_DEFAULT_TAKE);
  @HostBinding('class') className = 'my-6 block';
  url = input('');
  @Input()
  set info(info: PaginatorInfo) {
    this._info = info;
    this.resetCursor(info.currentPageIndex! + 1);
  }
  get info() {
    return this._info;
  }
  private _info!: PaginatorInfo;
  redirectMode = input(true);
  queryParams = input<Params | undefined>();
  totalCount = input(0);
  take = input(this.defaultTake);

  @Output() pageChange = new EventEmitter<number>();

  rootUrl = this._base.currentPath;

  currentPages: number[] = [];

  // 固定顯示前後兩頁面，若沒有就不顯示
  currentCursor = 1;

  redirectUrl = '';

  constructor(private _base: BaseService) {}

  ngOnInit(): void {
    this.redirectUrl = urlJoin(this.rootUrl, this.url(), 'page');
  }

  resetCursor(currentCursor: number) {
    this.currentCursor = currentCursor;
    const arr = [];
    for (
      let i = Math.max(this.currentCursor - 1, 2);
      i <= Math.min(this.currentCursor + 1, this.info.totalPageCount! - 1);
      i++
    ) {
      arr.push(i);
    }
    this.currentPages = arr;
  }

  handleClick(e: Event, page: number) {
    if (this.redirectMode()) return;

    this.pageChange.emit(page - 1);
  }
}
