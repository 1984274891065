import { Directive, OnDestroy } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class AutoDestroy implements OnDestroy {
  protected _destroy$ = new Subject<any>();

  /**
   * operator that make flow be stop when app destroy.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  takeUntilAppDestroy = takeUntil<any>(this._destroy$);

  ngOnDestroy(): void {
    this._destroy$.next(undefined);
    this._destroy$.unsubscribe();
  }
}
