import { TemplateRef } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

export class NgxRxAlertModel {
  constructor(
    public title: string,
    public message: string,
    public type: 'success' | 'warning' | 'info' | 'error' = 'info',
    public disableClose?: boolean,
    public hasInput?: boolean,
    public inputType: 'input' | 'textarea' = 'textarea',
    public inputValidator: ValidatorFn | ValidatorFn[] | null = null,
  ) {}
}

export class NgxRxAlertModel2 {
  constructor(
    public options: {
      message: string;
      title?: string;
      type?: 'success' | 'warning' | 'info' | 'error';
      disableClose?: boolean;
      hasInput?: boolean;
      inputType?: 'input' | 'textarea';
      inputProps?: {
        defaultValue?: string | number;
        type?: string;
      };
      inputValidator?: ValidatorFn | ValidatorFn[] | null;
      cancelText?: string;
      confirmText?: string;
      templateRef?: TemplateRef<HTMLElement>;
    },
  ) {}
}

export const enum DIALOG_TYPE {
  ALERT,
  CONFIRM,
}

export interface NgxRxAlertOption {
  noRedirect?: boolean;
  backdropStyle?: any;
  disabledBackdropClick?: boolean;
}
