export const computedUniqueKey = Symbol('computed');

/**
 * for component Class instance, if that Class have multiple instances be created, and you want only computed inside that class only, extend `ComputedAble` to make sure you get different computed result
 */
export const computed =
  (
    depsCallback: (instance: any) => any[],
    /**
     * get current computed `key`, like in angular, when component be rendered in multiple places, that will share
     */
    getKey?: (instance: any) => string,
  ) =>
  (target: any, key: string, descriptor: any) => {
    const valueMap = new Map<string, { value?: any; deps: any[] }>();
    const originalGet = descriptor.get;

    descriptor.get = function (this: any, ...args: any) {
      const key = getKey?.(this) || 'default';
      const newDeps = depsCallback(this);
      const prev = valueMap.get(key);

      const hasNewValue =
        !prev ||
        (prev &&
          newDeps.some((newValue, i) => {
            const oldValue = prev.deps?.[i];

            return newValue !== oldValue;
          }));

      if (!hasNewValue) {
        return prev.value;
      }

      const nextResult = originalGet.bind(this)();

      valueMap.set(key, {
        deps: newDeps,
        value: nextResult,
      });

      return nextResult;
    };

    return descriptor;
  };

export class ComputedAble {
  [computedUniqueKey] = Math.random();
}
