import { InjectionToken } from '@angular/core';

export const APP_TITLE = new InjectionToken<() => string>('APP_TITLE');
export const APP_PAGINATION_DEFAULT_TAKE = new InjectionToken<number>(
  'APP_PAGINATION_DEFAULT_TAKE',
);

export const FIREBASE = new InjectionToken<{
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}>('FIREBASE');

export const APP_URL = new InjectionToken<{
  base: string;
  nonAuthentication: string[];
}>('APP_URL');

export const GEOCODING_KEY = new InjectionToken<string>('GEOCODING_KEY');

export const CURRENT_LANG_GETTER = new InjectionToken<() => string>(
  'CURRENT_LANG_GETTER',
);
