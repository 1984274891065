import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { BehaviorSubject, filter, switchMap, take } from 'rxjs';

import { isBelongRootDep, ROLES } from '@alan-apps/api-interfaces';
import { User } from '@alan-apps/data-access';

import { ICache, storage } from '../decorators';
import { UserValidators } from '../validators';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements ICache {
  readonly storageKey = 'AuthService';

  @storage()
  token: string | null = null;

  @storage()
  userId: string | null = null;

  currentUser$ = new BehaviorSubject<Partial<User> | null>({
    id: this.token!,
  });

  logout$ = this.currentUser$.pipe(
    filter((x) => !!x),
    switchMap(() =>
      this.currentUser$.pipe(
        filter((x) => !x),
        take(1),
      ),
    ),
  );

  // when has user save token、
  set user(user) {
    this.currentUser$.next(user);
    if (!user) {
      this.token = null;
      this.userId = null;
    } else {
      this.userId = user.id!;
    }
  }
  get user(): Partial<User> | null {
    return this.currentUser$.value;
  }

  get role() {
    const user = this.user;
    const role = user?.role;

    return role;
  }

  get departmentId() {
    return this.user?.department?.id;
  }

  get isSystemRootDep() {
    const user = this.user;
    if (!user?.department) return false;

    return isBelongRootDep(user.department.id!);
  }

  get isAdmin() {
    if (!this.role) {
      return false;
    }

    return (
      (this.role.level === ROLES.ADMIN || this.role.level === ROLES.READONLY) &&
      this.isSystemRootDep
    );
  }

  get isManager() {
    if (!this.role) return false;

    return this.role.level === ROLES.MANAGER;
  }

  get isAdvance() {
    if (!this.role) return false;

    return this.role.level === ROLES.ADVANCE;
  }

  get isMember() {
    if (!this.role) return false;

    return this.role.level === ROLES.MEMBER;
  }

  get isSystemAdvance() {
    return this.isAdvance && this.isSystemRootDep;
  }

  get searchDepPermissions() {
    return this.isAdmin || this.isSystemAdvance;
  }

  /**
   * user that has manager permission, maybe admin or manager
   */
  get hasManagerPermission() {
    return this.isAdmin || this.isManager;
  }

  get hasAdvancePermission() {
    if (!this.role) return false;

    return this.role.level! >= ROLES.ADVANCE;
  }

  get hasMemberPermission() {
    if (!this.role) return false;

    return this.role.level! >= ROLES.MEMBER;
  }
}

export const passwordValidator = [
  UserValidators.hasNumber,
  UserValidators.hasAlphabet,
  UserValidators.noSpecial,
  Validators.minLength(6),
  Validators.maxLength(20),
];

export function ValidationEqualTo(key: string, key2: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control) return null;

    const aControl = control.get(key);
    const bControl = control.get(key2);

    if (!aControl || !bControl) return null;

    if (aControl?.value !== bControl?.value) {
      bControl.setErrors({ equalTo: true });
    } else {
      bControl.setErrors(null);
    }

    return null;
  };
}
