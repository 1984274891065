import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { firstValueFrom, map, Observable, Subject } from 'rxjs';

import { SelectionList } from '@alan-apps/utils';
import { input } from '@angular/core';

export type CheckedDoneWithReturn = {
  ids: any[];
  returnCallBack: (result: boolean) => void;
};

@Component({
  selector: 'ngx-selection-list',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, MatButtonModule],

  template: `
    <ng-template #enableTmpl>
      <div class="flex gap-2">
        <button
          mat-raised-button
          type="button"
          class="text-white"
          color="accent"
          (click)="toggleCheckedMode()"
        >
          {{ (enabled$ | async) ? '選擇完成' : label() }}
        </button>
        @if (enabled$ | async) {
          <button
            mat-raised-button
            type="button"
            color="warn"
            class="ml-2"
            (click)="selectionList.leaveCheckedMode()"
          >
            取消
          </button>
        }
      </div>
    </ng-template>

    <ng-template #checkAllTmpl let-data="data" let-class="class">
      @if (enabled$ | async) {
        <button
          type="button"
          class="pr-2"
          [ngClass]="class"
          (click)="selectAll(data)"
        >
          <mat-checkbox
            class="pointer-events-none"
            [indeterminate]="selectionList.all.indeterminate$ | async"
            [checked]="selectionList.all.checked$ | async"
            tabindex="-1"
          >
          </mat-checkbox>
        </button>
      }
    </ng-template>

    <ng-template #checkItem let-data="data" let-class="class">
      @if (enabled$ | async) {
        <mat-checkbox
          [ngClass]="class"
          [checked]="(selectionList.checkedIdsMap$ | async)![data.id]"
          #checkbox
        >
        </mat-checkbox>
        <div
          class="absolute z-[1] inset-0 cursor-pointer hover:bg-gray-400/20"
          (click)="toggleChecked($event, data)"
        ></div>
      }
    </ng-template>
  `,
  styles: [],
})
export class SelectionListComponent<T = any> implements OnInit {
  options = input.required<ConstructorParameters<typeof SelectionList<T>>[0]>();
  label = input('匯出');

  @Output() checkedDone = new EventEmitter<string[]>();
  /**
   *
   */
  @Output() checkedDoneWithReturn = new EventEmitter<CheckedDoneWithReturn>();

  return$ = new Subject<boolean>();

  @ViewChild('enableTmpl', { static: true }) enableTmpl!: TemplateRef<any>;
  @ViewChild('checkAllTmpl', { static: true }) checkAllTmpl!: TemplateRef<any>;
  @ViewChild('checkItem', { static: true }) checkItem!: TemplateRef<any>;

  selectionList!: SelectionList<T>;
  enabled$!: Observable<boolean>;
  wrapperClass$!: Observable<string>;

  ngOnInit() {
    this.selectionList = new SelectionList<T>(this.options());
    this.enabled$ = this.selectionList.enabled$;
    this.wrapperClass$ = this.enabled$.pipe(
      map((x) => (x ? 'select-none' : '')),
    );
  }

  toggleChecked($event: MouseEvent, data: T) {
    const id = this.selectionList.getCheckedKey(data);
    if ($event.shiftKey) {
      $event.preventDefault();
      $event.stopPropagation();
      this.selectionList.shiftToggleChecked(id);
    }
    this.selectionList.toggleChecked(id);
  }

  async toggleCheckedMode() {
    const selectionList = this.selectionList;
    if (selectionList.enabled) {
      const ids = selectionList.checkedIds;

      if (ids.length > 0) {
        if (this.checkedDoneWithReturn.observed) {
          const promise = firstValueFrom(this.return$);

          this.checkedDoneWithReturn.emit({
            ids,
            returnCallBack: (value: boolean) => this.return$.next(value),
          });

          const result = await promise;

          if (!result) return;
        } else {
          this.checkedDone.emit(ids);
        }
      }
    }

    this.selectionList.toggleCheckedMode();
  }

  selectAll(pagination: any) {
    this.selectionList.selectAll(pagination.nodes ?? pagination);
  }
}
