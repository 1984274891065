import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';

import { isMobile } from '@alan-apps/utils';

import { getI18nRouteLinkString } from '../pipes';
import { I18nService } from '../services';
import { input } from '@angular/core';

/**
 * when a href in mobile will be navigate by router
 *
 * in desktop be open in new tab
 */
@Directive({
  selector: 'a[externalRouterLink]',
  standalone: true,
})
export class ExternalRouterLinkDirective implements AfterViewInit {
  externalRouterLink = input.required<string[] | string>();
  forceExternalLink = input(false);
  i18n = inject(I18nService);

  isMobile = isMobile();

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    if (this.forceExternalLink() || !this.isMobile) return;

    e.preventDefault();
    const link = this.externalRouterLink();
    if (link instanceof Array) {
      this._router.navigate(link);
    } else {
      this._router.navigateByUrl(link);
    }
  }

  constructor(
    private _elm: ElementRef,
    private _render: Renderer2,
    private _router: Router,
  ) {}

  ngAfterViewInit() {
    const elm = this._elm.nativeElement;

    const language = this.i18n.language;
    const href = getI18nRouteLinkString(this.externalRouterLink(), language);
    this._render.setAttribute(elm, 'href', href);
    if (!this.isMobile) {
      this._render.setAttribute(elm, 'target', '_blank');
    }
  }
}
