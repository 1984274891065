import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  Inject,
  Injectable,
  NgZone,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { NgxRxAlertModel, NgxRxAlertService } from 'ngx-rx-alert';
import {
  BehaviorSubject,
  catchError,
  defer,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import {
  clearObjToForm,
  detectBot,
  detectBrowser,
  isMobile,
} from '@alan-apps/utils';

import { InfoService } from './info.service';
import { ViewService } from './view.service';

export type OpenWindowOptions = {
  /**
   * is add origin to open url, default is `true`
   *
   * @default `true`
   */
  autoOrigin?: boolean;
  /**
   * addition params
   */
  params?: { [key: string]: string };
  /**
   * `Optional`. Specifies the target attribute or the name of the window. The following values are supported:
   * - `_blank` - URL is loaded into a new window, or tab. This is default
   * - `_parent` - URL is loaded into the parent frame
   * - `_self` - URL replaces the current page
   * - `_top` - URL replaces any framesets that may be loaded
   * - `name` - The name of the window (Note: the name does not specify the title of the new window)
   */
  name?: string;
  /**
   * Optional. A comma-separated list of items, no whitespace.
   * The following values are supported: https://www.w3schools.com/jsref/met_win_open.asp
   */
  specs?: string;
};
interface ScrollToErrorFieldOptions {
  scrollElm?: HTMLElement;
  modifier?: number;
}

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  fbLinkShow$ = new BehaviorSubject(true);

  menuOpen$ = new BehaviorSubject(false);

  menuAddTemplate$ = new BehaviorSubject(null);

  currentPath = '/';

  get isManagePage() {
    return this.currentPath === '/manage';
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  currentBrowser = detectBrowser();

  isMobile = isMobile();
  isSafari = this.currentBrowser === 'Safari';

  isBot = detectBot(navigator?.userAgent);

  currentRouteChange$ = this._router.events.pipe(
    filter((val): val is NavigationEnd => val instanceof NavigationEnd),
    map((val: NavigationEnd) => val.url),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  currentRoutePath$ = defer(() =>
    this.currentRouteChange$.pipe(startWith(this._router.url)),
  );

  private _renderer: Renderer2 = this.rendererFactory.createRenderer(
    null,
    null,
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private _info: InfoService,
    private rendererFactory: RendererFactory2,
    private _alc: NgxRxAlertService,
    public _zone: NgZone,
    private _router: Router,
    private _view: ViewService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  toggleMenu(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.menuOpen$.next(!this.menuOpen$.value);
  }

  copyToClipboard(str: string, message?: string) {
    defer(() => navigator.clipboard.writeText(str))
      .pipe(
        // when clipboard is not supported, fallback to fallbackCopyToClipboard
        catchError(() => of(this.fallbackCopyToClipboard(str))),
        switchMap(() => this._info.show(message || '複製成功！')),
        catchError((err) => {
          return this._alc.alert(new NgxRxAlertModel('複製失敗', '', 'error'));
        }),
      )
      .subscribe();
  }

  private fallbackCopyToClipboard(str: string) {
    const el = this._renderer.createElement('textarea');
    this._renderer.setAttribute(el, 'readonly', '');
    this._renderer.setStyle(el, 'position', 'absolute');
    this._renderer.setStyle(el, 'left', '-9999px');
    this._renderer.appendChild(this.document.body, el);
    el.value = str;

    const selection = this.document.getSelection();
    const selected =
      selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
    el.select();

    this.document.execCommand('copy');
    this._renderer.removeChild(this.document.body, el);
    if (selected) {
      const selection = document.getSelection();
      if (!selection) return;
      selection.removeAllRanges();
      selection.addRange(selected);
    }
  }

  scrollToErrorField(
    elm: HTMLElement,
    {
      scrollElm = this._view.mainViewElm,
      modifier = -100,
    }: ScrollToErrorFieldOptions = {},
  ) {
    return of(null).pipe(
      tap((x) => {
        const errors = elm.querySelectorAll<HTMLElement>('.ng-invalid');

        if (errors.length > 0) {
          const targetErrorElm = errors.item(0);
          targetErrorElm.scrollIntoView({ behavior: 'auto', block: 'start' });
          scrollElm.scrollBy({ top: modifier });

          targetErrorElm.focus();
        }
      }),
      switchMap(() => this._info.show('請填寫所有必填欄位')),
    );
  }

  getSelection() {
    return this.document.getSelection();
  }

  openUrl(
    url: string,
    { params, name = '_blank', specs = '' }: OpenWindowOptions = {},
  ) {
    return this._zone.runOutsideAngular(() =>
      of(null).pipe(
        map(() => {
          if (params) {
            const queryString = new HttpParams({
              fromObject: clearObjToForm(params),
            }).toString();

            url = `${url}?${queryString}`;
          }

          const result = window.open(url, name, specs);

          return result;
        }),
      ),
    );
  }
}
