import {
  Attribute,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { input } from '@angular/core';

@Component({
  selector: 'ngx-symbol-flag',
  templateUrl: './symbol-flag.component.html',
  styleUrls: ['./symbol-flag.component.scss'],
  standalone: true,
})
export class SymbolFlagComponent implements OnInit {
  @HostBinding('class') classList =
    'block flag absolute elevation-2 text-white line-clamp-1 ' + this.className;

  @HostBinding('style.right.px') right?: number;
  @HostBinding('style.top.px') top?: number;
  @HostBinding('style.left.px') left?: number;
  @HostBinding('style.bottom.px') bottom?: number;
  @HostBinding('style.transform') transform?: string;

  size = input<'small' | 'bigger' | 'normal'>('normal');
  position = input<'left' | 'right'>('right');

  constructor(@Attribute('class') private className: string) {}

  ngOnInit() {
    const position = this.position();
    this.transform = position === 'right' ? `rotate(45deg)` : `rotate(-45deg)`;

    switch (this.size()) {
      case 'small':
        this.top = 2;
        this[position] = -45;
        break;
      case 'bigger':
        this.top = 15;
        this[position] = -30;
        break;

      case 'normal':
      default:
        this.top = 6;
        this[position] = -40;
        break;
    }
  }
}
